import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/1.webp" width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/2.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/3.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/4.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/5.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/6.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/7.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/8.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/9.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
					<li>
                        <img src="/assets/images/brand/10.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/11.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/12.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/13.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/14.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    {/* <li>
                        <img src="/assets/images/brand/15.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li> */}
                    <li>
                        <img src="/assets/images/brand/16.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/17.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/18.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
					<li>
                        <img src="/assets/images/brand/19.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/20.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/21.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/22.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/23.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/24.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/25.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/26.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/27.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
					<li>
                        <img src="/assets/images/brand/28.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/29.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/30.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/31.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/32.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/33.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/34.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/35.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/36.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
					<li>
                        <img src="/assets/images/brand/37.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
					<li>
                        <img src="/assets/images/brand/38.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    {/* <li>
                        <img src="/assets/images/brand/39.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li> */}
                    <li>
                        <img src="/assets/images/brand/40.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/41.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/42.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/43.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/44.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/45.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/46.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;