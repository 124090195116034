import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const Careers = () => {
    return (
        <>
            <PageHelmet pageTitle="Website Maintenance Services in Hyderabad | Digitoze" pageDescription="Do you need Website Maintenance Service in Hyderabad? Do you have a fantastic website but are unsure how to maintain it up to date? With our fantastic ☑️website maintenance service DIGITOZE - Located @ Hyderabad, we've got you covered." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Careers'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start About Area */}
                <div className="about-area ptb--60 bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title"> Be A Part Of The Best Digital Marketing Company In Hyderabad </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.webp" alt="Careers"/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div id="service" className="fix">
                    <div className="service-area creative-service-wrapper ptb--40 bg_color--1" >
                        <div className="container creative-service">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center align-items-center">
                                    <h4>Digital Marketing Executive</h4>
                                    <p>We would love to share a similar experience and how I learned some valuable lessons.</p>
                                    <div className="slide-btn"><a href="/contact" className='rn-button-style--2 btn-solid text-center align-items-center'>Apply Now</a></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center align-items-center">
                                    <h4>Graphic Artist</h4>
                                    <p>We would love to share a similar experience and how I learned some valuable lessons.</p>
                                    <div className="slide-btn"><a href="/contact" className='rn-button-style--2 btn-solid text-center align-items-center'>Apply Now</a></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center align-items-center">
                                    <h4>Web Designer</h4>
                                    <p>We would love to share a similar experience and how I learned some valuable lessons.</p>
                                    <div className="slide-btn"><a href="/contact" className='rn-button-style--2 btn-solid text-center align-items-center'>Apply Now</a></div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {/* End Service Area  */} 
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default Careers;