import React, { Component } from "react";
//import ReactDOM from 'react-dom';
import {FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
//import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";


const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/DIGITOZE/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/digitoze/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/digitoze1/?hl=en'},
    // {Social: <FaTwitter /> , link: 'https://twitter.com/digitoze/'},
]

class Footer extends Component{
    render(){
        return(
           <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="footer-link">
                                                <h4>Our Services</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/">Home</a></li>
                                                    <li><a href="/about-us">About Us</a></li>
                                                    <li><a href="/how-we-work">Work Process</a></li>
                                                    <li><a href="/digiserve">Services</a></li>
                                                    <li><a href="/our-portfolio">Portfolio</a></li>
                                                    <li><a href="/blog">Blog</a></li>
                                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                                    <li><a href="/contact">Contact</a></li>
                                                </ul>
                                            </div>
                                            <div className="social-share-inner">
                                                <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                    {SocialShare.map((val , i) => (
                                                        <li key={i}><a target="_blank" rel="noopener noreferrer" href={`${val.link}`}>{val.Social}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-8 col-sm-8 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Contact Info:</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/contact">Digitoze Consulting Services LLP, First Floor, Plot No 6-2-47 Mega city No. 1714, Yeturu Towers, A.C.Guards, Hyderabad, 500004</a></li>
                                                    <li><a href="tel:+919849256286">India +91 - 984 925 6286 </a></li>
                                                    <li><a href="tel:+917337420744">India +91 - 733 742 0744 </a></li>
                                                    {/* <li><a href="tel:+17149122903">USA: +1 714 912 2903</a></li> */}
                                                    <li><a href="mailto:info@digitoze.com">info@digitoze.com</a></li>
                                                </ul>

                                                 
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:admin@example.com">admin@example.com</a></li>
                                                    <li><a href="mailto:hr@example.com">hr@example.com</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div> */}
                                        {/* End Single Widget  */}

                                        {/* Toggle Start Widget  */}
                                        {/* <div className="toggler">
                                            <div id="effect2" className="ui-widget-content ui-corner-all">
                                                <a href="tel:+919885098850">
                                                    <i className="fa fa-phone stylephone"></i>
                                                </a><br/>
                                                <a  target="_blank" href="https://web.whatsapp.com/send?phone=9885098850">
                                                <i className="fa fa-whatsapp stylewhatsapp" ></i>
                                                </a>
                                            </div>
                                            </div>

                                            <div className="toggler1">
                                            <div id="effect1" className="ui-widget-content ui-corner-all">
                                                <div className="card">
                                                <div className="card-body">
                                                    <form action="#" >
                                                    <div className="form-group">
                                                        <input type="email" className="form-control" placeholder="Your Name *" id="email"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" placeholder="Your Email *" id="pwd"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" placeholder="Enter Phone Number *" id="phone"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" placeholder="Write a Subject" id="subject"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea type="text" className="form-control" id="item04" name="message" placeholder="Your Message"></textarea>
                                                    </div>
                                                    <button type="submit" className="btn btn-success btn-block" >Submit</button>
                                                    </form>
                                                </div>
                                                </div>
                                            </div>
                                            </div> */}
                                        
                                        {/* <a id="button"><img src={`/assets/images/cross.png`} /></a>
                                        <a id="button"><img src={`/assets/images/form.png`} /></a> */}

                                        {/* <a id="button1"><img src={`/assets/images/cross.png`} class="form"/></a>
                                        <a id="button2"><img src={`/assets/images/form.png`} class="chat"/></a> */}
                                        
                                        {/* Toggle End Widget  */}

                                            

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2024 <a herf="digitoze.com/digitoze-new2" target="_blank" rel="noopener noreferrer"  >Digitoze</a>. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer