import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const DigitalMarketingList = [
    {
        icon: <FiCast />,
        title: 'Search Engine Optimization',
        description: 'Our team of experts will optimize the brand / product for amplified visibility and high search rankings in the search engine using SEO strategies which will help in acquiring more leads and customers.',
        buttonLink: '/search-engine-optimization'
    },
    {
        icon: <FiLayers />,
        title: 'Social Media Marketing',
        description: 'Social media marketing is an effective medium to inform and showcase your brand online in various social media platforms, using both organic social media and paid advertising, which will in turn strengthen your brand’s foothold and deliver sales.',
        buttonLink: '/social-media-marketing'
    },
    {
        icon: <FiUsers />,
        title: 'Online Reputation Management',
        description: 'Online reputation management can deliver a positive customer insight of your brand and motivate them to buy your product or services. Our trained experts will perceive your business class and will build an online profile which will be in accordance to your brand',
        buttonLink: '/online-reputation-management'
    },
    { 
        icon: <FiMonitor />,
        title: 'Paid Ad Management',
        description: 'Paid Ads is the leading process to inform & educate your brand to the world in the quickest possible way. Our experts will design the campaigns by targeting the relevant audience to get the best and profitable return on your investment.',
        buttonLink: '/paid-ad-management'
    },
    {
        icon: <FiUsers />,
        title: 'Creative Content Development',
        description: 'Creative content development is the procedure to transform the content from simple brand awareness to the point where your brand will be considered as the solution to their challenge. Our content experts understands this process and will design high ranking content, which is an essential part of an ad campaign which in turn will drive the sales.',
        buttonLink: '/creative-content-development'
    },
    { 
        icon: <FiMonitor />,
        title: 'Lead Generation',
        description: 'At Digitoze, we offer qualified online lead generation services to suit your business requirements. We generate targeted leads with great qualification matrix and bring excellent ROI. We provide one stop solution for lead generation; validate leads & finally Business in the inbox.',
        buttonLink: '/lead-generation'
    }
]


class DigitalMarketingThree extends Component{
    render(){
        const {column } = this.props;
        const DigitalMarketingContent = DigitalMarketingList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {DigitalMarketingContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={`${val.buttonLink}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default DigitalMarketingThree;
