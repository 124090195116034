import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsSEO extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We target keywords that are right for your business. Our highly experienced SEO team will ensure that your website is attractive and stands out to users across the globe searching for your products or services. As the best SEO agency, we are committed to working closely with our clients to offer a level of best service that reflects our core values.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Our highly experienced SEO team will help you achieve the highest ranking for your keywords. We do an in-depth review and analysis of your website and come up with powerful strategies so that we can increase the number of visitors to your site and also improve the rank by using different keywords. We work relentlessly to achieve the best possible results for our clients. </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We have a team of highly-trained SEO experts who will learn about your business and target those keywords that match the intent of your customers. We ensure to get your website ranked for keywords that matter to your business the most. We aim to deliver results aligning with your business goals, saving you time and effort. </p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsSEO;