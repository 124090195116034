import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabWebCAD from "../elements/tab/TabWebCAD";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const CustomApplicationDevelopment = () => {
    return (
        <>
            <PageHelmet pageTitle="Custom Application Development" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Custom Application Development'}   />
            {/* End Breadcrump Area */}
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                  {/* Start About Area */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/webcad.webp" alt="Custom Application Development"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--20">
                                                        <p className="description">As the business landscape keeps changing, your digital platform should be in sync with the market trends. Choosing the right technology for your business is a difficult task since many technologies exist for similar tasks. With the adoption of the right technologies by our technically competent team, Digitoze offers smart solutions to address your business challenges. </p>

                                                        <p className="description">Digitoze develops tailor-made software solutions for your business needs, and integrates them with your existing workflow, and automates complex processes with ease. Schedule a conversation with us and we will gain an insight into your brand ideas and use them to shape a solid plan for success.</p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabWebCAD tabStyle="tab-style--1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default CustomApplicationDevelopment;