import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
//import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
//import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";

//const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle="Contact Top Digital Marketing Agencies & Services in Hyderabad | Dubai | USA | Australia - Digitoze" pageDescription="Contact Digitoze - One of the Top Digital Marketing Agencies & Services in Hyderabad | Dubai | USA | Australia served more than 500 Customers with Best Satisfaction." />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Contact Us'}   />
                {/* End Breadcrump Area */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact With Phone Number</h4>
                                        <p><a href="tel:+919849256286">India: +91 - 984 925 6286</a></p>
                                        <p><a href="tel:+917337420744">India: +91 - 733 742 0744</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:info@digitoze.com">info@digitoze.com</a></p>
                                        <p><a href="mailto:support@digitoze.com">support@digitoze.com</a></p>
                                        <p><a href="mailto:enquiry@digitoze.com">enquiry@digitoze.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p># First Floor, Plot No 6-2-47 Mega city No. 1714, Yeturu Towers, A.C.Guards, Hyderabad, 500004</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <iframe title="Digitoze Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.154024052305!2d78.4574329!3d17.3979368!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24d504febeeb7538!2sDigitoze%20Consulting%20Services%20LLP!5e0!3m2!1sen!2sin!4v1657903409999!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                {/* End Contact Map  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                

                {/* Start Contact Map  */}
                {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={17.404377}
                            lng={78.459284}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
                {/* End Contact Map  */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

                {/* Start Footer Buttons Area  */}
                <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}
                
            </React.Fragment>
        )
    }
}
export default Contact