import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// const TabOne = [
//     {
//         image: '/assets/images/portfolio/apparel/apparel01.webp',
//         bigImage: '/assets/images/portfolio/apparel/apparel01.webp',
//         category: 'Apparel',
//         title: 'MAX - Galleria Mall'
//     },
//     {
//         image: '/assets/images/portfolio/cargo/cargo01.webp',
//         bigImage: '/assets/images/portfolio/cargo/cargo01.webp',
//         category: 'Cargo',
//         title: 'Alpha Star'
//     },
//     {
//         image: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
//         bigImage: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
//         category: 'E-Commerce',
//         title: 'Bliss'
//     },
//     {
//         image: '/assets/images/portfolio/education-training/education-training01.webp',
//         bigImage: '/assets/images/portfolio/education-training/education-training01.webp',
//         category: 'Education & Training',
//         title: 'IIM Nagpur'
//     },
//     {
//         image: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
//         bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
//         category: 'Events & Exhibition',
//         title: 'Business Women Expo'
//     },
//     {
//         image: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
//         bigImage: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
//         category: 'Food & Beverages',
//         title: 'Zohran Global'
//     },
    
   
// ]


const TabTwo = [
    {
        image: '/assets/images/portfolio/apparel/apparel01.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel01.webp',
        category: 'Apparel',
        title: 'MAX - Galleria Mall'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel02.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel02.webp',
        category: 'Apparel',
        title: 'Lifestyle'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel03.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel03.webp',
        category: 'Apparel',
        title: 'Hyderabad Next'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel04.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel04.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Premia'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel05.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel05.webp',
        category: 'Apparel',
        title: 'Go Colors'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel06.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel06.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria'
    },
    // {
    //     image: '/assets/images/portfolio/apparel/apparel07.webp',
    //     bigImage: '/assets/images/portfolio/apparel/apparel07.webp',
    //     category: 'Apparel',
    //     title: 'Zivame'
    // },
    {
        image: '/assets/images/portfolio/apparel/apparel08.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel08.webp',
        category: 'Apparel',
        title: 'Trends - Hyderabad Next Premia'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel09.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel09.webp',
        category: 'Apparel',
        title: 'Go Colors - Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel10.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel10.webp',
        category: 'Apparel',
        title: 'Zudio - Hyderabad Next Premia'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel11.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel11.webp',
        category: 'Apparel',
        title: 'Decathlon'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel12.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel12.webp',
        category: 'Apparel',
        title: 'MarksandSpencer'
    },
    // {
    //     image: '/assets/images/portfolio/apparel/apparel13.webp',
    //     bigImage: '/assets/images/portfolio/apparel/apparel13.webp',
    //     category: 'Apparel',
    //     title: 'MarksandSpencer'
    // },
    {
        image: '/assets/images/portfolio/apparel/apparel14.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel14.webp',
        category: 'Apparel',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel15.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel15.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria-Musarambagh'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel16.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel16.webp',
        category: 'Apparel',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel18.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel18.webp',
        category: 'Apparel',
        title: 'Lifestyle'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel19.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel19.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria-Musarambagh'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel20.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel20.webp',
        category: 'Apparel',
        title: 'Hyderabad Next'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel21.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel21.webp',
        category: 'Apparel',
        title: 'E-Galleria'
    },
    // {
    //     image: '/assets/images/portfolio/apparel/apparel22.webp',
    //     bigImage: '/assets/images/portfolio/apparel/apparel22.webp',
    //     category: 'Apparel',
    //     title: 'Zivame'
    // },
    {
        image: '/assets/images/portfolio/apparel/apparel23.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel23.webp',
        category: 'Apparel',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel24.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel24.webp',
        category: 'Apparel',
        title: 'Lifestyle'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel25.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel25.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria-Punjagutta'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel26.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel26.webp',
        category: 'Apparel',
        title: 'Max'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel27.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel27.webp',
        category: 'Apparel',
        title: 'MarksandSpencert'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel28.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel28.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria-Musarambagh'
    },
    // {
    //     image: '/assets/images/portfolio/apparel/apparel29.webp',
    //     bigImage: '/assets/images/portfolio/apparel/apparel29.webp',
    //     category: 'Apparel',
    //     title: 'Hyderabad Next Galleria-Musarambagh'
    // },
    {
        image: '/assets/images/portfolio/apparel/apparel30.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel30.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel31.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel31.webp',
        category: 'Apparel',
        title: 'Lifestyle'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel32.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel32.webp',
        category: 'Apparel',
        title: 'Hyderabad Next Galleria-Musarambagh'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel33.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel33.webp',
        category: 'Apparel',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel34.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel34.webp',
        category: 'Apparel',
        title: 'E-Galleria'
    },
    // {
    //     image: '/assets/images/portfolio/apparel/apparel35.webp',
    //     bigImage: '/assets/images/portfolio/apparel/apparel35.webp',
    //     category: 'Apparel',
    //     title: 'Zivame'
    // },
    // {
    //     image: '/assets/images/portfolio/apparel/apparel17.webp',
    //     bigImage: '/assets/images/portfolio/apparel/apparel17.webp',
    //     category: 'Apparel',
    //     title: 'Zivame'
    // },
]

const TabThree = [
    {
        image: '/assets/images/portfolio/cargo/cargo01.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo01.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo02.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo02.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo03.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo03.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo04.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo04.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo05.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo05.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo06.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo06.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo07.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo07.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo08.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo08.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo09.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo09.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo10.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo10.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo11.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo11.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo12.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo12.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo13.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo13.webp',
        category: 'Cargo',
        title: 'First priority Cargo'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo14.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo14.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo15.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo15.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
]

const TabFour = [
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce02.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce02.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce03.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce03.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce04.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce04.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce05.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce05.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce06.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce06.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce07.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce07.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce08.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce08.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce09.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce09.webp',
        category: 'E-Commerce',
        title: 'TopValueSolar'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce10.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce10.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce11.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce11.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce12.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce12.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce13.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce13.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce14.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce14.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce15.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce15.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce16.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce16.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce17.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce17.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce18.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce18.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce19.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce19.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce20.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce20.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce21.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce21.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce22.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce22.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce23.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce23.webp',
        category: 'E-Commerce',
        title: 'TopValueSolar'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce24.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce24.webp',
        category: 'E-Commerce',
        title: 'NutraBio'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce25.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce25.webp',
        category: 'E-Commerce',
        title: 'TradeLeaves'
    },
]

const TabFive = [
    {
        image: '/assets/images/portfolio/education-training/education-training01.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training01.webp',
        category: 'Education & Training',
        title: 'IIM Nagpur'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training02.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training02.webp',
        category: 'Education & Training',
        title: 'IIM Nagpur'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training03.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training03.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training04.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training04.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training05.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training05.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training06.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training06.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training07.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training07.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training08.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training08.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training09.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training09.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training10.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training10.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training11.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training11.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training13.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training13.webp',
        category: 'Education & Training',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training14.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training14.webp',
        category: 'Education & Training',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training15.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training15.webp',
        category: 'Education & Training',
        title: 'Ni-msme'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training16.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training16.webp',
        category: 'Education & Training',
        title: 'Ni-msme'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training17.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training17.webp',
        category: 'Education & Training',
        title: 'Ace-Trainig & Consulting'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training18.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training18.webp',
        category: 'Education & Training',
        title: 'Ace-Trainig & Consulting'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training19.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training19.webp',
        category: 'Education & Training',
        title: 'Ace-Trainig & Consulting'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training20.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training20.webp',
        category: 'Education & Training',
        title: 'Ace-Trainig & Consulting'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training21.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training21.webp',
        category: 'Education & Training',
        title: 'Ms Education Academy'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training22.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training22.webp',
        category: 'Education & Training',
        title: 'Ms Education Academy'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training23.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training23.webp',
        category: 'Education & Training',
        title: 'Ms Education Academy'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training12.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training12.webp',
        category: 'Education & Training',
        title: 'AvGlobal'
    },
]

const TabSix = [
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition02.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition02.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition03.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition03.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition04.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition04.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition05.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition05.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition06.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition06.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition07.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition07.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition08.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition08.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition09.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition09.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition10.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition10.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition11.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition11.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition12.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition12.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition13.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition13.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition14.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition14.webp',
        category: 'Events & Exhibition',
        title: 'The Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition15.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition15.webp',
        category: 'Events & Exhibition',
        title: 'CloudCuckooLand'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition16.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition16.webp',
        category: 'Events & Exhibition',
        title: 'CloudCuckooLand'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition17.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition17.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition18.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition18.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition19.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition19.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition20.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition20.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition21.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition21.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition22.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition22.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition23.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition23.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition24.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition24.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition25.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition25.webp',
        category: 'Events & Exhibition',
        title: 'Adms e-Bikes'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition26.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition26.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition27.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition27.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition28.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition28.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition29.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition29.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition30.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition30.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition31.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition31.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition32.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition32.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition33.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition33.webp',
        category: 'Events & Exhibition',
        title: 'Water India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition34.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition34.webp',
        category: 'Events & Exhibition',
        title: 'Hyderabad Next Premia'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition35.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition35.webp',
        category: 'Events & Exhibition',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition36.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition36.webp',
        category: 'Events & Exhibition',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition37.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition37.webp',
        category: 'Events & Exhibition',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition38.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition38.webp',
        category: 'Events & Exhibition',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition39.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition39.webp',
        category: 'Events & Exhibition',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition40.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition40.webp',
        category: 'Events & Exhibition',
        title: 'Gambol'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition41.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition41.webp',
        category: 'Events & Exhibition',
        title: 'Telangana Gym Owners'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition42.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition42.webp',
        category: 'Events & Exhibition',
        title: 'Hitex Sports Expo India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition43.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition43.webp',
        category: 'Events & Exhibition',
        title: 'GreenSourceBook'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition44.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition44.webp',
        category: 'Events & Exhibition',
        title: 'Grahasti'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition45.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition45.webp',
        category: 'Events & Exhibition',
        title: 'GreenSourceBook'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition46.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition46.webp',
        category: 'Events & Exhibition',
        title: 'GreenSourceBook'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition47.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition47.webp',
        category: 'Events & Exhibition',
        title: 'Hitex Sports Expo India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition48.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition48.webp',
        category: 'Events & Exhibition',
        title: 'HyderabadKidsFair'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition49.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition49.webp',
        category: 'Events & Exhibition',
        title: 'Hitex Sports Expo India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition50.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition50.webp',
        category: 'Events & Exhibition',
        title: 'Hitex Sports Expo India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition51.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition51.webp',
        category: 'Events & Exhibition',
        title: 'Hitex Sports Expo India'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition52.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition52.webp',
        category: 'Events & Exhibition',
        title: 'DelhiGreenShow2019'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition53.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition53.webp',
        category: 'Events & Exhibition',
        title: 'GrainTechIndia2021'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition54.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition54.webp',
        category: 'Events & Exhibition',
        title: 'GrainTechIndia2021'
    },
]

const TabSeven = [
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
        category: 'Food & Beverages',
        title: 'Zohran Naturals'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages02.webp',
        bigImage: '/assets/images/portfolio/food-beverages/cfood-beverages02.webp',
        category: 'Food & Beverages',
        title: 'Zohran Naturals'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages03.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages03.webp',
        category: 'Food & Beverages',
        title: 'Need Fresh'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages04.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages04.webp',
        category: 'Food & Beverages',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages05.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages05.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages06.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages06.webp',
        category: 'Food & Beverages',
        title: 'Need Fresh'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages07.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages07.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages08.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages08.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages09.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages09.webp',
        category: 'Food & Beverages',
        title: 'Need Fresh'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages10.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages10.webp',
        category: 'Food & Beverages',
        title: 'Zohran Naturals'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages11.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages11.webp',
        category: 'Food & Beverages',
        title: 'Need Fresh'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages12.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages12.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages13.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages13.webp',
        category: 'Food & Beverages',
        title: 'Need Fresh'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages14.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages14.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages15.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages15.webp',
        category: 'Food & Beverages',
        title: 'CafeCoffeeDay'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages16.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages16.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages17.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages17.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Premia'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages18.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages18.webp',
        category: 'Food & Beverages',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages19.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages19.webp',
        category: 'Food & Beverages',
        title: 'Zohran Naturals'
    },
]

const TabEight = [
    {
        image: '/assets/images/portfolio/furniture/furniture01.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture01.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture02.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture02.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture03.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture03.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture04.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture04.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture05.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture05.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture06.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture06.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture08.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture08.webp',
        category: 'Furniture',
        title: 'GksWorkspace'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture09.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture09.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture10.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture10.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture11.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture11.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture12.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture12.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture13.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture13.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture14.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture14.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture15.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture15.webp',
        category: 'Furniture',
        title: 'Divajumeriah'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture16.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture16.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture17.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture17.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture18.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture18.webp',
        category: 'Furniture',
        title: 'GksWorkspace'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture19.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture19.webp',
        category: 'Furniture',
        title: 'MaheshModularCarpenter'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture20.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture20.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
    {
        image: '/assets/images/portfolio/furniture/furniture21.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture21.webp',
        category: 'Furniture',
        title: 'UrbanScienceInteriors'
    },
]

const TabNine = [
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences01.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences01.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences02.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences02.webp',
        category: 'Health Sciences',
        title: 'Apollo 24/7'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences03.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences03.webp',
        category: 'Health Sciences',
        title: 'HearingSolutions'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences04.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences04.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences05.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences05.webp',
        category: 'Health Sciences',
        title: 'HearingSolutions'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences06.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences06.webp',
        category: 'Health Sciences',
        title: 'Apollo 24/7'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences07.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences07.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences08.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences08.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences09.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences09.webp',
        category: 'Health Sciences',
        title: 'Apollo 24/7'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences10.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences10.webp',
        category: 'Health Sciences',
        title: 'Apollo White Dental'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences11.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences11.webp',
        category: 'Health Sciences',
        title: 'Clove Dental'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences12.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences12.webp',
        category: 'Health Sciences',
        title: 'Apollo White Dental'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences13.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences13.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences14.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences14.webp',
        category: 'Health Sciences',
        title: 'Clove Dental'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences15.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences15.webp',
        category: 'Health Sciences',
        title: 'HearingSolutions'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences16.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences16.webp',
        category: 'Health Sciences',
        title: 'Apollo White Dental'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences17.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences17.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences18.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences18.webp',
        category: 'Health Sciences',
        title: 'Phic Expo 2020'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences19.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences19.webp',
        category: 'Health Sciences',
        title: 'Apollo 24/7'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences20.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences20.webp',
        category: 'Health Sciences',
        title: 'Apollo White Dental'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences21.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences21.webp',
        category: 'Health Sciences',
        title: 'Maa E.N.T Hospitals'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences22.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences22.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences23.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences23.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences24.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences24.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences25.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences25.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences26.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences26.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences27.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences27.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences28.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences28.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences29.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences29.webp',
        category: 'Health Sciences',
        title: 'AlAzmath'
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences30.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences30.webp',
        category: 'Health Sciences',
        title: 'Maa Hearing Aids'
    },
]

const TabTen = [
    {
        image: '/assets/images/portfolio/hitech/hi-tech01.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech01.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech02.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech02.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech03.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech03.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech04.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech04.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech05.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech05.webp',
        category: 'Hi-Tech',
        title: 'Smart Minds'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech06.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech06.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech07.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech07.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech08.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech08.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech09.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech09.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech10.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech10.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech11.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech11.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech12.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech12.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech13.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech13.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech14.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech14.webp',
        category: 'Hi-Tech',
        title: 'Digitoze'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech15.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech15.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech16.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech16.webp',
        category: 'Hi-Tech',
        title: 'Smart Minds'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech17.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech17.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech18.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech18.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech19.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech19.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech20.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech20.webp',
        category: 'Hi-Tech',
        title: 'Envision'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech21.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech21.webp',
        category: 'Hi-Tech',
        title: 'Smart Minds'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech22.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech22.webp',
        category: 'Hi-Tech',
        title: 'TetraSoft'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech23.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech23.webp',
        category: 'Hi-Tech',
        title: 'TetraSoft'
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech24.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech24.webp',
        category: 'Hi-Tech',
        title: 'TetraSoft'
    },
]

const TabEleven = [
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing01.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing01.webp',
        category: 'Manufacturing',
        title: 'Green Vehicle Expo'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing02.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing02.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing03.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing03.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing04.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing04.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing05.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing05.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing06.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing06.webp',
        category: 'Manufacturing',
        title: 'Weston Genius'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing07.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing07.webp',
        category: 'Manufacturing',
        title: 'Green Vehicle Expo'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing08.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing08.webp',
        category: 'Manufacturing',
        title: 'Weston Genius'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing09.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing09.webp',
        category: 'Manufacturing',
        title: 'Manufacturing'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing10.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing10.webp',
        category: 'Manufacturing',
        title: 'Manufacturing'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing11.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing11.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing12.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing12.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing13.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing13.webp',
        category: 'Manufacturing',
        title: 'Green Vehicle Expo'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing14.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing14.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing15.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing15.webp',
        category: 'Manufacturing',
        title: 'Weston Genius'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing16.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing16.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing17.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing17.webp',
        category: 'Manufacturing',
        title: 'Weston Genius'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing18.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing18.webp',
        category: 'Manufacturing',
        title: 'Green Vehicle Expo'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing19.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing19.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing20.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing20.webp',
        category: 'Manufacturing',
        title: 'Ac Solutions'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing21.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing21.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing22.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing22.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing23.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing23.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing24.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing24.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing25.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing25.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing26.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing26.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing27.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing27.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing28.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing28.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing29.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing29.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing30.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing30.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing31.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing31.webp',
        category: 'Manufacturing',
        title: 'Irtiqa'
    },
]

const TabTwelve = [
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment01.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment01.webp',
        category: 'Media & Entertainment',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment02.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment02.webp',
        category: 'Media & Entertainment',
        title: 'RedLion'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment03.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment03.webp',
        category: 'Media & Entertainment',
        title: 'Pvr Cinemas'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment04.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment04.webp',
        category: 'Media & Entertainment',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment05.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment05.webp',
        category: 'Media & Entertainment',
        title: 'RedLion'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment06.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment06.webp',
        category: 'Media & Entertainment',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment07.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment07.webp',
        category: 'Media & Entertainment',
        title: 'Pvr'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment08.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment08.webp',
        category: 'Media & Entertainment',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment09.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment09.webp',
        category: 'Media & Entertainment',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment10.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment10.webp',
        category: 'Media & Entertainment',
        title: 'RedLion'
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment11.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment11.webp',
        category: 'Media & Entertainment',
        title: 'RedLion'
    },
]

const TabThirteen = [
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous01.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous01.webp',
        category: 'Miscellaneous',
        title: 'Diva'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous02.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous02.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous03.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous03.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous04.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous04.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous05.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous05.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous06.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous06.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous07.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous07.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Premia'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous08.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous08.webp',
        category: 'Miscellaneous',
        title: 'Rendercon'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous09.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous09.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous10.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous10.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous11.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous11.webp',
        category: 'Miscellaneous',
        title: 'E-Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous12.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous12.webp',
        category: 'Miscellaneous',
        title: 'Rendercon'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous13.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous13.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous14.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous14.webp',
        category: 'Miscellaneous',
        title: 'Diva'
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous15.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous15.webp',
        category: 'Miscellaneous',
        title: 'Hyderabad Next Galleria'
    },
]

const TabFourteen = [
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality01.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality01.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality02.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality02.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality03.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality03.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality04.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality04.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality05.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality05.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality06.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality06.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality07.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality07.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality08.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality08.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality09.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality09.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality10.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality10.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality11.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality11.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality12.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality12.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality13.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality13.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality14.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality14.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality15.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality15.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality16.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality16.webp',
        category: 'Travel & Hospitality',
        title: 'Superescapes'
    },
]

class TabStyleThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const {column } = this.props;
        const { tab2, tab3, tab4, isOpen } = this.state;
        return (
            <div>
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    {/* <Tab><span>All Projects</span></Tab> */}
                                    <Tab><span>Apparel</span></Tab>
                                    <Tab><span>Cargo</span></Tab>
                                    <Tab><span>E-Commerce</span></Tab>
                                    <Tab><span>Education &amp; Training</span></Tab>
                                    <Tab><span>Events &amp; Exhibition</span></Tab>
                                    <Tab><span>Food &amp; Beverages</span></Tab>
                                    <Tab><span>Furniture</span></Tab>
                                    <Tab><span>Health Sciences</span></Tab>
                                    <Tab><span>Hi-Tech</span></Tab>
                                    <Tab><span>Manufacturing</span></Tab>
                                    <Tab><span>Media &amp; Entertainment</span></Tab>
                                    <Tab><span>Miscellaneous</span></Tab>
                                    <Tab><span>Travel &amp; Hospitality</span></Tab>
                                </TabList>
                            </div>
                        </div>
                    </div>

                    {/* <TabPanel className="row row--35">
                        {TabOne.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabOne[tab1].bigImage}
                                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                        prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + 1) % TabOne.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="All Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel> */}
            
                    <TabPanel className="row row--35">
                        {TabTwo.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabTwo[tab2].bigImage}
                                        nextSrc={TabTwo[(tab2 + 1) % TabTwo.length]}
                                        prevSrc={TabTwo[(tab2 + TabTwo.length - 1) % TabTwo.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab2: (tab2 + TabTwo.length - 1) % TabTwo.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab2: (tab2 + 1) % TabTwo.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Apparel Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabThree.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabThree[tab3].bigImage}
                                        nextSrc={TabThree[(tab3 + 1) % TabThree.length]}
                                        prevSrc={TabThree[(tab3 + TabThree.length - 1) % TabThree.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab3: (tab3 + TabThree.length - 1) % TabThree.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab3: (tab3 + 1) % TabThree.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab3: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFour.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFour[tab4].bigImage}
                                        nextSrc={TabFour[(tab4 + 1) % TabFour.length]}
                                        prevSrc={TabFour[(tab4 + TabFour.length - 1) % TabFour.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFour.length - 1) % TabFour.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFour.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="E-Commerce Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFive.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFive[tab4].bigImage}
                                        nextSrc={TabFive[(tab4 + 1) % TabFive.length]}
                                        prevSrc={TabFive[(tab4 + TabFive.length - 1) % TabFive.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFive.length - 1) % TabFive.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFive.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Education &amp; Training Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabSix.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Events &amp; Exhibition Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabSeven.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Food &amp; Beverages Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabEight.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Furniture Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabNine.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Health &amp; Sciences Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabTen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Hi-Tech Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabEleven.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Manufacturing Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabTwelve.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Media &amp; Entertainment Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabThirteen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Miscellaneous Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFourteen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Travel &amp; Hospitality Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                </Tabs>
            </div>
        )
    }
}


export default TabStyleThree
