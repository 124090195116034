import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
//import CounterOne from "../elements/counters/CounterOne";
//import Accordion01About from "../elements/AccordionAbout";
//import Testimonial from "../elements/Testimonial";
//import BrandTwoInner from "../elements/BrandTwoInner";
//import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import TabAll from "../elements/tab/TabAll";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
//import { FiCheck } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";

class OurPortfolio extends Component{
    render(){
        let title = '☑️Digital Marketing Agency | Social Media Marketing | SEO Services Company in Hyderabad | Web Development',
        description = 'Top 10 Best Web & Digital Media Marketing Advertising Agency -➡️ Digitoze - Located @Hyderabad - Maximize Your Business Growth Digitally. ☑️Digitoze offers robust digital marketing full-service for your business.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle={title} pageDescription={description} />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Our Portfolio'}   />
                {/* End Breadcrump Area */}

                {/* Start Designer Portfolio  */}
                <div className="designer-portfolio-area ptb--60 bg_color--1">
                    <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                        <TabAll column="col-lg-4 col-md-6 col-sm-6 col-12" />
                    </div>
                </div>
                {/* End Designer Portfolio  */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

            </React.Fragment>
        )
    }
}
export default OurPortfolio