import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsWebDv extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>No matter the work you need for your business, we will take the time to truly understand your brand, product or service and ensure that we fulfil your objectives.</p>                                           
                                           <p>Our website development team works relentlessly to ensure that your website stays ahead of the digital curve. We pride ourselves on delivering high-quality services that truly make a difference.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Our team of talented website developers provides expert web application development services to our clients and will also work with the client to help them identify the services they need. </p>
                                           <p>We are good at developing complex back-end system integrations that would provide good support to the team responsible for managing your website. We will work with you to ensure that your workflow boosts interactivity. </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digitoze is a well-established dedicated website development company in India. We will provide you with solutions that enable you to achieve the desired results through your website. </p>
                                           <p>We can build anything, from a content management system to custom Apps. Whatever your project, tell us about your goals and we will show you how you can achieve it.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsWebDv;