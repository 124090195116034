import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import BrandOne from "../elements/Brand";
import { FiCheck } from "react-icons/fi";
//import BrandTwo from "../elements/BrandTwo";


const EduConsult = () => {
    return (
        <>
            <PageHelmet pageTitle="EduConsult" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'EduConsult'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                  {/* Start About Area */}
                  <div className="about-area pt--60 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center text-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title align-items-center text-center">
                                            <p className="description">Digitoze, with its expert Edu-Consultants and strategic partners in the field of digital education provides complete digital solutions to educational sector, which includes building latest digital infrastructure for institutions &amp; academies with its effective &amp; high ranking consultancy.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}                                   

                 {/* Start About Area */}
                 <div className="about-area pb--60 pt--20 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/educonsult1.webp" alt="EduConsult"/>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="about-inner inner">
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Design &amp; Content</h3>
                                                    <ul className='list-style--1'>
                                                        <li><FiCheck /> Brand Promotions Development</li>
                                                        <li><FiCheck /> Full Social Media Services</li>
                                                        <li><FiCheck /> Multi-Channel Content</li>
                                                        <li><FiCheck /> PR &amp; Advertorial Services - Strategy</li>
                                                        <li><FiCheck /> SEO based Content Generation</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Strategy &amp; Research</h3>
                                                    <ul className='list-style--1'>
                                                        <li><FiCheck /> Complete Strategy </li>
                                                        <li><FiCheck /> Research &amp; Insights Generation Services </li>
                                                        <li><FiCheck /> Integrated Communications </li>
                                                        <li><FiCheck /> Planning &amp; Strategy </li>
                                                        <li><FiCheck /> Advanced Data Analytics </li>
                                                        <li><FiCheck /> Consumer Behavior Tracking </li>
                                                        <li><FiCheck /> Research, Development and Interpretation </li>                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Digital Media Performance</h3>
                                                    <ul className='list-style--1'>
                                                        <li><FiCheck /> Digital Media Strategy </li>
                                                        <li><FiCheck /> Planning and Buying Performance </li>
                                                        <li><FiCheck /> Marketing &amp; Lead Generation </li>
                                                        <li><FiCheck /> Classic Media Strategy </li>
                                                        <li><FiCheck /> Planning and Buying </li>
                                                        <li><FiCheck /> Conversion Rate Optimization Services </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Web Solutions</h3>
                                                    <ul className='list-style--1'>
                                                        <li><FiCheck /> E-Commerce Consultancy </li>
                                                        <li><FiCheck /> Development and Promotion </li>
                                                        <li><FiCheck /> Web Design and Development </li>
                                                        <li><FiCheck /> Integrated Marketing Application </li>
                                                        <li><FiCheck /> Website Performance </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">User Acquisition Strategies</h2>
                                        <ul className='list-style--1'>
                                            <li><FiCheck /> Create High quality Content</li>
                                            <li><FiCheck /> Paid Marketing Campaigns</li>
                                            <li><FiCheck /> Use Industry-related Keywords</li>
                                            <li><FiCheck /> Remarketing </li>
                                            <li><FiCheck /> Create Optimized Landing Pages</li>
                                            <li><FiCheck /> Send Customized Emails</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/educonsult2.webp" alt="EduConsult Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/educonsult.webp" alt="EduConsult Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Theme & Integration</h2>
                                        <p>We have the best digital team, which produces outstanding results. We will design your website, choose a relevant layout and develop a creative theme for your business to grow. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                 {/* Start Brand Area  */}
                 <div className="rn-brand-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default EduConsult;