import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class AccordionDC extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        SETTING UP DIGITAL TEAM
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>We will be of service and assist in Recruitment, Training, Setting up Process, Hand Holding Process & finally Handover. Setting up & monitoring team will be on shared resource basis, so that companies do not have to bear the burden of hiring Digital Marketing Managers or Head of strategies and save from incurring big spend.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        BUSINESS SETUP FOR STARTUPS
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>We will be of service and assist in Setting up Business / Offices, Statutory requirement and expand business across India, Middle East / GCC & South Asia region.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        FOR MSME
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Digitoze can help small & mid-sized companies to provide complete end to end analysis of current business on digital platforms and also provide road map for enterprises to scale their business on digital ecosystem.</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        )
    }
}

export default AccordionDC;





