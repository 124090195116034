// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
//import Demo from './page-demo/Demo';

// Digitoze Components

import Homepage from './digitoze/Homepage';
import About from "./digitoze/About";
import HowWeWork from "./digitoze/HowWeWork";
import OurTeam from "./digitoze/OurTeam";
import HappyClients from "./digitoze/HappyClients";
import OurPortfolio from "./digitoze/OurPortfolio";
import PartneringWithUs from "./digitoze/PartneringWithUs";
import Testimonials from "./digitoze/Testimonials";
import Careers from "./digitoze/Careers";

import DigiServe from "./digitoze/DigiServe";
import DigitalMarketing from "./digitoze/DigitalMarketing";
import SearchEngineOptimization from "./digitoze/SearchEngineOptimization";
import SocialMediaMarketing from "./digitoze/SocialMediaMarketing";
import OnlineReputationManagement from "./digitoze/OnlineReputationManagement";
import PaidAdManagement from "./digitoze/PaidAdManagement";
import CreativeContentDevelopment from "./digitoze/CreativeContentDevelopment";
import LeadGeneration from "./digitoze/LeadGeneration";
import WebDesignDevelopment from "./digitoze/WebDesignDevelopment";
import WebDesign from "./digitoze/WebDesign";
import WebDevelopment from "./digitoze/WebDevelopment";
import CMSDevelopment from "./digitoze/CMSDevelopment";
import CustomApplicationDevelopment from "./digitoze/CustomApplicationDevelopment";
import ECommerceSolutions from "./digitoze/ECommerceSolutions";
import WebsiteMaintenance from "./digitoze/WebsiteMaintenance";
import IndustrialSolution from "./digitoze/IndustrialSolution";
import DigitizementofFactory from "./digitoze/DigitizementofFactory";
import DigitalMarketingCaseStudies from "./digitoze/DigitalMarketingCaseStudies";
import SalesandMarketingAutomation from "./digitoze/SalesandMarketingAutomation";
import DigiConsult from "./digitoze/DigiConsult";
import DigiCademy from "./digitoze/DigiCademy";
import EduConsult from "./digitoze/EduConsult";
import Blog from "./digitoze/Blog";
import DigitozeBestDigitalMarketingTrendsIn2023 from "./digitoze/DigitozeBestDigitalMarketingTrendsIn2023";
import SeoTrends2023 from "./digitoze/SeoTrends2023";

import Contact from "./digitoze/Contact";

import PrivacyPolicy from "./digitoze/PrivacyPolicy";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
//import BLogList from './elements/blog/BlogList';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/how-we-work`} component={HowWeWork}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/our-team`} component={OurTeam}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/happy-clients`} component={HappyClients}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/our-portfolio`} component={OurPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/partnering-with-us`} component={PartneringWithUs}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonials`} component={Testimonials}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Careers}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digiserve`} component={DigiServe}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digital-marketing`} component={DigitalMarketing}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/search-engine-optimization`} component={SearchEngineOptimization}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/social-media-marketing`} component={SocialMediaMarketing}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/online-reputation-management`} component={OnlineReputationManagement}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paid-ad-management`} component={PaidAdManagement}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-content-development`} component={CreativeContentDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/lead-generation`} component={LeadGeneration}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/web-design-development`} component={WebDesignDevelopment}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/web-design`} component={WebDesign}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/web-development`} component={WebDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/cms-development`} component={CMSDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/custom-application-development`} component={CustomApplicationDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/ecommerce-solutions`} component={ECommerceSolutions}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/website-maintenance`} component={WebsiteMaintenance}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/industrial-solutions`} component={IndustrialSolution}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digitizement-of-factory`} component={DigitizementofFactory}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/digital-marketing-case-studies`} component={DigitalMarketingCaseStudies}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/sales-and-marketing-automation`} component={SalesandMarketingAutomation}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digiconsult`} component={DigiConsult}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/digicademy`} component={DigiCademy}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/educonsult`} component={EduConsult}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digitoze-best-digital-marketing-trends-in-2023`} component={DigitozeBestDigitalMarketingTrendsIn2023}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/seo-trends-2023`} component={SeoTrends2023}/>


                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>
                        
                    
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();