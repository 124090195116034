import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const PartneringWithUs = () => {
    return (
        <>
            <PageHelmet pageTitle="Custom Web App Development Services in India" pageDescription="Digitoze,  ☑️best custom app development company in Hyderabad, offers amazing custom app development services. So contact us straight away if you'd like to take your company to the next level in the industry." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Partnering With Us'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start About Area 2 */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Why Partner With Us</h2>
                                        <p>We believe in long-term relationships. We help our clients to build new businesses, create sustainable business opportunities and work towards a win-win situation for us.</p>

                                        <p>We give expert advice to our partners and believe in providing them with the highest quality of service possible. We aim to be your trusted partner and we operate with full transparency and honesty.</p>
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/contact">Contact Us Now</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-5.webp" alt="Partnering With Us"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area 2 */}

                {/* Start About Area 2 */}
                <div className="rn-about-area pb--80 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                            <div className="thumbnail custom-align-center">
                                    <img  src="/assets/images/msme.webp" alt="MSME Partner"/>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div className="thumbnail custom-align-center">
                                    <img  src="/assets/images/startupindia.webp" alt="Startup India Partner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area 2 */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default PartneringWithUs;