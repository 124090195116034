import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsISDF extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We will analyze the digital readiness of the factory, develop a strategy with a clear road map, discover new revenue channels and optimize. We will provide cloud-based solutions that allow you to deploy digital solutions efficiently at less cost. Digitization gives an instant boost to productivity. It helps the manufacturers figure out the best way to adapt and allows them to hit more aggressive deadlines.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digitoze offers custom solutions that answer the specific needs of the client. We help our clients understand the operation of analytics solutions in a production environment and guide them in choosing the right one. Modern factory software is often complicated to use, and it requires a lot of training to understand. We offer simple-to-use, custom interface layers and custom-solutions to simplify the work.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digitization helps make data available in quickly-extractable digital form and would lead to speedy decision making, decrease costs, and gain market advantage. Real-time monitoring and reporting through data consolidation enables data-driven decision-making. Digitoze helps you integrate existing tools and equipment of your company to attain maximum efficiency and create a future-proof digital enterprise.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsISDF;