import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabSMM from "../elements/tab/TabSMM";
import { FiCheck } from "react-icons/fi";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const SocialMediaMarketing = () => {
    return (
        <>
            <PageHelmet pageTitle="Best Expert Social Media Marketing Company" pageDescription="In digitoze, the best social media marketing expert. We offer a comprehensive range of ☑️digital marketing services to help your business grow both locally and globally." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Social Media Marketing'}   />
            {/* End Breadcrump Area */}
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/smm1.webp" alt="Social Media Marketing"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title ptb--20">
                                            {/* <h2 className="title">Search Engine Optimization</h2> */}
                                            <p className="description">Social media marketing is an effective medium to inform and showcase your brand online in various social media platforms (Facebook, Twitter, Instagram, LinkedIn etc.).</p>

                                            <p className="description">We should combine both organic social media and paid advertising in our extensive strategy.</p>

                                            <p className="description">Paid advertising increases audience growth and conveys your content faster to the right audience whereas organic social media growth takes longer but keeps your audience engaged with you in long term basis.</p>

                                            <p className="description">Without an extensive strategy, you will end up wasting money, time and resources. Therefore, our social media marketing experts will devise and plan a proper customized strategy according to your business goals, objectives &amp; budget, which will in turn strengthen your brand’s foothold and deliver sales.</p>

                                            <h5>How can we be of Service</h5>

                                            <ul className='list-style--1'>
                                            <li><FiCheck /> Devising Extensive Strategy</li>
                                            <li><FiCheck /> Quality Creative Content</li>
                                            <li><FiCheck /> Regular Posting Procedure</li>
                                            <li><FiCheck /> Clear Reporting</li>
                                            <li><FiCheck /> Audience Outreach &amp; Engagement</li>
                                            <li><FiCheck /> Proper Use of Resources</li>
                                            </ul>
                                        </div>
                                        <div className="row mt--30">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="row mt--30">
                                            <TabSMM tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/smm2.webp" alt="Social Media Marketing"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default SocialMediaMarketing;