import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabWebEC from "../elements/tab/TabWebEC";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const ECommerceSolutions = () => {
    return (
        <>
            <PageHelmet pageTitle="E-Commerce Solutions" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'E-Commerce Solutions'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                  {/* Start About Area */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/webec.webp" alt="E-Commerce Solutions"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--20">
                                                        <p className="description">A feature-rich e-commerce platform is vital for success. We at Digitoze use our years of experience in e-commerce to provide an end-to-end e-commerce solution and give our clients the cutting edge when it comes to driving online sales. We will update your existing e-commerce platform or build a new one from scratch using our responsive themes. All our themes are responsive, and they work seamlessly on laptops, computers, tablets, and smartphone devices.</p>

                                                        <p className="description">Digitoze is the best e-commerce website development company in Hyderabad and we use the best e-commerce website design. Our clients rave about the support we provide to users of our platform and how easy it is to use.</p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabWebEC tabStyle="tab-style--1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default ECommerceSolutions;