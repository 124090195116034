import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsWebCMS extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>A person without any technical expertise in HTML, PHP, CSS, or other coding languages can easily create functional pages, upload, edit, and delete content from a website with the help of CMS. A smart content management system will help you streamline almost all of your day-to-day demands.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We have plenty of experience customizing and building additional plug-ins for popular CMS platforms like Joomla, Magento, WordPress, Umbraco, and Drupal. We can suggest the system best suited to the type of website you want us to build. We can also personalize front- and back-ends development in tune with your content strategies.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digitoze provides the best CMS development services. We design custom content management systems for the high performance of your websites by offering a rich functionality set. We are also popular as the best WordPress development company in Hyderabad. Contact Digitoze today to learn more about how we can help your company find success with your website project.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsWebCMS;