import React, { Component ,Fragment } from "react";
import BlogContent from "./BlogContent";

class BLogList extends Component{
    render() {
        const PostList = BlogContent.slice(0 , 3);
        return (
             <Fragment>
                 <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href="/seo-trends-2023">
                                        <img className="w-100" src={`/assets/images/about/about-4.webp`} alt="SEO Trends 2023 | Best SEO Services | Qatar | Dubai | Saudi Arabia | UK"/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">SEO</p>
                                    <h4 className="title"><a href="/seo-trends-2023">SEO Trends 2023 | Best SEO Services | Qatar | Dubai | Saudi Arabia | UK</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href="/seo-trends-2023">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href="/digitoze-best-digital-marketing-trends-in-2023">
                                        <img className="w-100" src={`/assets/images/about/about-4.webp`} alt="Digitoze - Best Digital Marketing trends in 2023"/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">SEO</p>
                                    <h4 className="title"><a href="/digitoze-best-digital-marketing-trends-in-2023">Digitoze - Best Digital Marketing trends in 2023</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href="/digitoze-best-digital-marketing-trends-in-2023">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                 </div>
             </Fragment>
        );
    }
}
export default BLogList;