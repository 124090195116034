import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsWebCAD extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>By collaborating with us, your digital platform becomes unique. We have a proven record of high customer satisfaction across various business services. We provide world-class custom application development services for businesses of all sizes. We will help you automate your processes so that you can concentrate on your core business and deliver better service.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>At Digitoze, we are highly focused on delivering quality deliverables. We adopt agile methodologies for the execution of the needs of the clients. Our dedicated team would provide strategic solutions that fit even the most complex custom application development projects. We are the industry leaders when it comes to harnessing innovative technologies for the rapidly evolving markets.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We are committed to your excellence and we are entirely inclined to your business requirements. With efficient delivery to the production environment, our team offers you the promise of a speedier return on investment. Our application development solutions are designed with principles based on scalability and robustness in mind to serve your business needs, now and in the long run.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsWebCAD;