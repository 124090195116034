import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsWebEC extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digitoze partners with its customers to help them accelerate their online sales growth. We provide platforms that keep up with the changing pace of the online business. We are specialists in providing complex and customized solutions by taking advantage of our e-commerce framework to create and evolve websites that help to differentiate our customers in these competitive markets.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>With Digitoze e-commerce solutions, everything you need to market, merchandise, and manage your e-commerce website is available from one easy-to-use interface supported by our highly experienced e-commerce team.</p>
                                           <p>Our team constantly updates its knowledge with the emerging e-commerce trends and ensures that your website gets noticed and attracts customers. We bring your platform to life with interactive and engaging features.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We will advise you in creating the right e-commerce platform that suits your budget, size, and type of business. We will support your e-commerce platforms and optimize them through our remote support and application-managed services. Our support team provides "Best-in-Class" and will help you build, launch, and grow your online business- every step of the way.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsWebEC;