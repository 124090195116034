import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
import BrandTwo from "../elements/BrandTwo";

const HappyClients = () => {
    return (
        <>
            <PageHelmet pageTitle="⏩Web Development Company | Wordpress Development Company" pageDescription="DIGITOZE, Happy to Client for Deliverable, will work collaboratively with you to design a responsive, fully functional, and creative website that will meet all of your business requirements." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Happy Clients'}   />
            {/* End Breadcrump Area */}
            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start Brand Area  */}
                {/* <div className="rn-brand-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}
                
                {/* Start Brand Area  */}
                <div className="rn-brand-area brand-separation bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default HappyClients;