import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabCCD from "../elements/tab/TabCCD";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const CreativeContentDevelopment = () => {
    return (
        <>
            <PageHelmet pageTitle="Best Creative Content Development Company |  Digitoze Hyderabad " pageDescription="List of the Best Content Marketing Companies, TOP Professional Creative Content Agencies in the World We help enterprises in booming in the digital age. Content can help your brand grow both locally and globally." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Creative Content Development'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/ccd.webp" alt="Creative Content Development"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title ptb--20">
                                            {/* <h2 className="title">Search Engine Optimization</h2> */}
                                            <p className="description">Content is the soul of a digital marketing strategy. In this digital era, every brand tries to grab the attention of its potential customers with creative content. Carefully crafted content can keep your customers engaged with your brand. Our experienced content team generates vital top-notch content to help your brand reap huge dividends. </p>

                                            <p className="description">Digitoze is the best content writing agency in Hyderabad. We create superior quality content that’s interesting and drives sales. Our content team will first analyze your brand requirements, do extensive research and deliver the final draft after multiple rounds of proofreading and editing to get the best content.</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabCCD tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default CreativeContentDevelopment;