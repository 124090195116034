import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsWebDs extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Our approach is about creating smart web experiences that are tailor-made as per the requirements of our clients. We transform your digital space by using agile processes that are end-user-focused, and we ensure that every customer stays engaged in your brand.</p>                                           
                                           <p>We know that every project is unique, and with our excellent web design services, we can add real value to your business</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digitoze is the best web design company in India. We have a skilled team of full-stack web designers and developers who build feature-packed, high-performing, and secure websites. We ensure that there is a smooth digital transformation.</p>
                                           <p>Our dedicated team works closely to attain outstanding results that provide long-term value, using the latest technology and approaches</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Our web design agency is known for its transparent approach to our clients so that we can maximize the success of every project we deliver.</p>
                                           <p>We are genuinely passionate about what we do, and we aim to build lasting relationships with every client. Our goal is to produce a perfect solution that works well on all the devices and operating systems and is easy to maintain.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsWebDs;