import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsSMM extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>From branding to sales, our social media marketing experts will help you achieve your desired goals. Through our effective social media marketing approach, we can increase traffic to your website, boost brand awareness, engage with online communities, easily reach new customers, target potential customers with paid campaigns, and also gain insights into your customer’s purchasing habits.</p>

                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Having a strong presence across multiple digital channels is vital for any business. Our social media management team will create powerful campaigns that will not only help increase your website traffic but will also help you reach the desired audience. We use graphics, blog posts, videos, and much more to keep your digital touchpoints appealing.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We will plan and deliver unforgettable, exciting, and engaging social media marketing services and a communication-driven persona for your business to give you the competitive edge. We build brand personality and increase customer loyalty and familiarity. We ensure that your business stands out in the digital crowd. Share your vision, and we will help you transform it into a reality.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsSMM;