import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabORM from "../elements/tab/TabORM";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const OnlineReputationManagement = () => {
    return (
        <>
            <PageHelmet pageTitle="☑️Top Online Reputation Management Companies" pageDescription="Top online reputation management in Hyderabad that will help you manage your online presence while working to ensure your company's longevity. ➡️DIGITOZE should be contacted immediately." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Online Reputation Management'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/orm.webp" alt="Onlune Reputation Manageement"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title ptb--20">
                                            {/* <h2 className="title">Search Engine Optimization</h2> */}
                                            <p className="description">We understand that reputation of a company/ business/ brand/ individual is most valuable asset and even a small reputation crisis can be devastating for the business. If your business reputation is at stake due to severe negative content on internet search engines, then it’s high time to seriously start thinking about protecting it.  </p>

                                            <p className="description">Digitoze is the most trusted name in the online reputation management. We have experts in protecting, repairing and restoring your online reputation. We will bury/remove malicious information on different search results engines, clean up your digital footprint, and protect your online reputation. We will help your reputation stand out for the right reasons online. </p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabORM tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default OnlineReputationManagement;