import React, { Component , Fragment } from "react";
//import ModalVideo from 'react-modal-video';
import PageHelmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import { slickDot } from "../page-demo/script";
import TestimonialContent from "../elements/testimonial/TestimonialContent";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import CallAction from "../elements/callaction/CallAction";
//import Team from "../blocks/team/TeamTwo";
//import Accordion01 from "../elements/Accordion";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
//import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import PortfolioListHome from "../elements/portfolio/PortfolioListHome";
import TabThree from "../elements/tab/TabThree";
import ContactOne from "../elements/contact/ContactOne";
//import Brand from "../elements/Brand";
//import Testimonial from "../elements/Testimonial";

const SlideList = [
    
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--35',
        category: '',
        title: '',
        descriptiona: '',
        descriptionb: '',
        descriptionc: '',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--32',
        category: '',
        title: 'The Ultimate',
        descriptiona: 'Creative Business',
        descriptionb: 'In Market',
        descriptionc: 'The Best Digital Marketing Company in India',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--33',
        category: '',
        title: 'Lets Create',
        descriptiona: 'Deep Creative',
        descriptionb: 'Demand For Your Business',
        descriptionc: 'With Digitozes Lead Generation Program',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--34',
        category: '',
        title: 'Lets Create',
        descriptiona: 'Deep Creative',
        descriptionb: 'Demand For Your Business',
        descriptionc: 'With Digitozes Lead Generation Program',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    
]

const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'DIGISERVE',
        description: 'At Digitoze, we deliver pertinent digital .',
        buttonLink: '/digiserve'
    },
    {
        icon: <FiLayers />,
        title: 'DIGICONSULT',
        description: 'Our Digital Consultancy helps you in actualization.',
        buttonLink: '/digiconsult'
    },
    {
        icon: <FiUsers />,
        title: 'DIGICADEMY',
        description: 'Academy, with its hands on industry experience.',
        buttonLink: '/digicademy'
    },
    {
        icon: <FiUsers />,
        title: 'EDUCONSULT',
        description: 'Entrepreneurship Eco System - Setting up.',
        buttonLink: '/educonsult'
    },
]

// const HowWeWork = [
//     {
//         icon: <FiCast />,
//         title: 'DIGISERVE',
//         description: 'At Digitoze, we deliver pertinent digital .',
//         buttonLink: '/digiserve'
//     },
//     {
//         icon: <FiLayers />,
//         title: 'DIGICONSULT',
//         description: 'Our Digital Consultancy helps you in actualization.',
//         buttonLink: '/digiconsult'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'DIGICADEMY',
//         description: 'Academy, with its hands on industry experience.',
//         buttonLink: '/digicademy'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'EDUCONSULT',
//         description: 'Entrepreneurship Eco System - Setting up.',
//         buttonLink: '/educonsult'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'EDUCONSULT',
//         description: 'Entrepreneurship Eco System - Setting up.',
//         buttonLink: '/educonsult'
//     },
// ]

const starndardService = [
    {
        image: '01',
        title: 'Hi-Tech',
    },
    {
        image: '02',
        title: 'Health Sciences',
    },
    {
        image: '03',
        title: 'Manufacturing',
    },
    {
        image: '04',
        title: 'E-Commerce',
    },
    {
        image: '05',
        title: 'Travel & Hospitality',
    },
    {
        image: '06',
        title: 'Media & Entertainment',
    },
    {
        image: '07',
        title: 'Education & Training',
    },
    {
        image: '08',
        title: 'Events & Exhibition',
    },

]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        // var namesItemOne = [
        //     'The Philosophy Of business analytics',
        //     'Fast-Track Your business',
        //     'Lies And Damn Lies About business',
        //     'The Ultimate Deal On business',
        // ];
        // var namesItemTwo = [
        //     'Proof That business Really Works',
        //     'Here Is What You Should Do For Your business',
        //     'The Hidden Mystery Behind business',
        // ];
        const PostList = BlogContent.slice(0 , 5);
        const TestimonialList = TestimonialContent.slice(0 , 5);
        //let title = 'Homepage 123',
        //description = 'Homepage We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design & Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability & innovation that reflects in our work whereas, providing very collaborating & inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most.';
        
        return(
            <Fragment> 
                
                <PageHelmet pageTitle="Best Digital Marketing Agency | Social Media SEO Services | PPC Company | Web Design & Development Company" pageDescription="Digitoze - Best Digital Marketing Agency - Hyderabad - Web Development agency offers a range of online marketing services, such as SEO Services, ☑️PPC campaign, ☑️Best social media marketing, ☑️web design and web development company." />
                {/* <Helmet pageTitle={title} pageDescription={description} /> */}

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.descriptiona ? <p className="description da">{value.descriptiona}</p> : ''}
                                                    {value.descriptionb ? <p className="description db">{value.descriptionb}</p> : ''}
                                                    {value.descriptionc ? <p className="description dc">{value.descriptionc}</p> : ''}
                                                    {/* {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* Start Blog Area */}
                <div className="rn-blog-area pt--0 pb--40 bg_color--5 below-slider-wrap">
                    <div className="container">
                        <div className="row mt--0 mt_sm--0 rn-slick-dot  slickdot--20 row--0">
                            <div className="col-lg-12">
                                <Slider {...slickDot}>
                                    {PostList.map((value , i ) => (
                                        <div className="blog blog-style--11" key={i}>
                                            {/* <div className="thumbnail">
                                                <a href="/blog-details">
                                                    <img src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                                </a>
                                            </div> */}
                                            <div className="content">
                                                <div className="icon">
                                                    <img src={`/assets/images/blog/blog-icon-${value.images}.webp`} alt="Blog Images"/>
                                                </div>
                                                <h4 className="title">{value.title}</h4>
                                                <p className="blogtype">{value.description}</p>
                                                {/* <div className="blog-btn">
                                                    <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>    
                    </div>    
                </div>
                {/* End Blog Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                    <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Get Your Website Analysis Report</h2>
                                    </div>
                                </div>
                            </div>
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                            <div className="contact-form--1 report">
                                <div className="container">
                                    <div className="row row--35 align-items-start">
                                        <div className="col-lg-12 order-2 order-lg-1">
                                            <div className="form-wrapper">
                                                <form action="https://digitoze.com/email-analysis.php" method="POST">
                                                    <label htmlFor="item01">
                                                        <input
                                                            type="url"
                                                            name="website"
                                                            id="item01"
                                                            required="true"
                                                            value={this.state.rnName}
                                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                                            placeholder="Your Website URL *"
                                                        />
                                                    </label>

                                                    <label htmlFor="item02">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="item02"
                                                            required="true"
                                                            value={this.state.rnEmail}
                                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                                            placeholder="Your Email *"
                                                        />
                                                    </label>
                                                    <label htmlFor="item03">
                                                        <input
                                                            type="phone"
                                                            name="phone"
                                                            id="item03"
                                                            required="true"
                                                            value={this.state.rnPhone}
                                                            onChange={(e)=>{this.setState({rnPhone: e.target.value});}}
                                                            placeholder="Your Phone *"
                                                        />
                                                    </label>
                                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start Service Area - Main 4 Boxes */}
                <div className="service-area creative-service-wrapper ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper creative-service">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.buttonLink}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                 {/* Start About Area  */}
                 <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/2.webp" width="auto" height="auto" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Complete Web &amp; Digital Solutions</h2>
                                        <p>We are a perfect digital marketing company in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, and other Digital Marketing Services.</p>
                                        <p>We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most.</p>
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/about-us">About Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Portfolio Area - Our Services*/}
            <div id="portfolio" className="fix bg_color--1 footer-left how-it-wrap">
                <div className="portfolio-area  ptb--20">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title hww text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">How We Work</h2>
                                        <p>Our Work Process</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <ul className="process-wrapp process-box">
                                <li className="whitecolor wow fadeIn" data-wow-delay="300ms" > <span className="pro-step bottom20">01</span>
                                <p className="fontbold bottom20">Concept</p>
                                <p className="mt-n2 mt-sm-0">We Analyze the concept of the requirement.</p>
                                </li>
                                <li className="whitecolor wow fadeIn" data-wow-delay="400ms" > <span className="pro-step bottom20">02</span>
                                <p className="fontbold bottom20">Plan</p>
                                <p className="mt-n2 mt-sm-0">Plan it accordingly to achieve the goals</p>
                                </li>
                                <li className="whitecolor wow fadeIn active" data-wow-delay="500ms" > <span className="pro-step bottom20">03</span>
                                <p className="fontbold bottom20">Design</p>
                                <p className="mt-n2 mt-sm-0">Our Experts Design the site as per the needs.</p>
                                </li>
                                <li className="whitecolor wow fadeIn" data-wow-delay="600ms"> <span className="pro-step bottom20">04</span>
                                <p className="fontbold bottom20">Development</p>
                                <p className="mt-n2 mt-sm-0">We Develop the project using clean and elegant code.</p>
                                </li>
                                <li className="whitecolor wow fadeIn" data-wow-delay="700ms"> <span className="pro-step bottom20">05</span>
                                <p className="fontbold bottom20">Quality Check</p>
                                <p className="mt-n2 mt-sm-0">Our QA team will analyze the website/app and execute it.</p>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}



                 {/* Start Portfolio Area - Our Services*/}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--60 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Services</h2>
                                        <p>Our team is a creative powerhouse with the tools and resources you need to grow your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioListHome styevariation="text-center mt--40" column="col-lg-3 col-md-6 col-sm-6 col-12" item="4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}





                {/* Start Counterup Area */}
                {/* <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div> */}
                {/* End Counterup Area */}


                
                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Industries Verticals We Work For</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                             {/* Start Single Service  */}
                            <div className="col-lg-12">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-3 col-md-3 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}.webp`} width="100%" height="100%" alt="Industry Verticals"/>
                                                </div>
                                                <div className="content">
                                                    {/* <h3><a href="/service-details">{value.title}</a></h3> */}
                                                    <h3>{value.title}</h3>
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}

               

                {/* Start COntact Area */}
                <div id="contact" className="fix">
                    <div className="rn-contact-area ptb--60 bg_color--5">
                        <ContactOne />
                    </div>
                </div>
                {/* End COntact Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">What People Say</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--25 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
                            <div className="col-lg-12">
                                <Slider {...slickDot}>
                                    {TestimonialList.map((value , i ) => (
                                        <div className="blog blog-style--1 custom-review-wrap" key={i}>
                                            <div className="thumbnail">
                                                <a href="/">
                                                    <img src={`/assets/images/blog/reviews-bg-new.webp`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content custom-review">
                                                <div className="review-stars">
                                                <img src={`/assets/images/review-stars.webp`} alt="digitoze reviews"/>
                                                </div>
                                                <h4 className="title">{value.title}</h4>
                                                <p className="blogtype">{value.category}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="about-button mt--0 text-center align-items-center">
                            <a className="rn-button-style--2 btn-solid" href="/testimonials">Read More</a>
                        </div>
                            </div>
                        </div> 
                        {/* <Testimonial /> */}
                    </div>
                </div>
                {/* End Testimonial Area */}
                
                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne/>
                                <div className="about-button mt--50 text-center align-items-center">
                            <a className="rn-button-style--2 btn-solid" href="/happy-clients">Check Out More</a>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start Designer Portfolio  */}
                <div className="designer-portfolio-area ptb--60 bg_color--1">
                    <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                        <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        <div className="about-button mt--50 text-center align-items-center">
                            <a className="rn-button-style--2 btn-solid" href="/our-portfolio">Our Portfolio</a>
                        </div>
                    </div>
                </div>
                {/* End Designer Portfolio  */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;