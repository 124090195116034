import React, { Component } from "react";

class OurTeams extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                <h2 className="title">Meet Our Exceptionally Talented Team Of Digital Natives</h2>
                                <p>Our team draws broad Digital Marketing experience to create the most powerful outcomes for our clients. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12 text-center order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/ourteam/1.webp" alt="Team Chart"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default OurTeams;