import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabISCS from "../elements/tab/TabISCS";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const DigitalMarketingCaseStudies = () => {
    return (
        <>
            <PageHelmet pageTitle="Digital Marketing Case Studies" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Digital Marketing Case Studies'}   />
            {/* End Breadcrump Area */}
            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                  {/* Start About Area */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/iscs.webp" alt="Digital Marketing Case Studies"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--20">
                                                        {/* <h2 className="title">Search Engine Optimization</h2> */}
                                                        <p className="description">Digital marketing case studies can provide in-depth knowledge about the uncertain situations faced by the digital agencies, the measures they took to overcome them, and how they obtained the end results. By analyzing good digital marketing case studies, one can easily gain meaningful insights into different businesses. </p>

                                                        <p className="description">In digital marketing, case studies act as proof to provide customers with the relevant information to determine whether they are making a good decision or not. At Digitoze, we provide the best digital marketing case studies to help our clients get desired results for unique situations. </p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabISCS tabStyle="tab-style--1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default DigitalMarketingCaseStudies;