import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsPAM extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We create bespoke ad campaigns by using a variety of creative techniques. Our team regularly updates themselves with the latest changes in the algorithms and sets high-performing campaigns that are right for your business. We regularly track your ad performance at every stage to optimize your ad campaigns and make sure that you stay ahead of the competition</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We are specialists in developing successful strategies for your business, even in the most competitive industries. We manage your ad campaign on relevant blogs, news websites, and other niche web pages. Our dedicated team is always ready to answer all your queries and offer support whether face-to-face, over the phone or online (Zoom meeting, Google meet, etc.). We are here to help you and your business grow. </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Our advertising agency covers every aspect needed to run a successful paid ad campaign. We ensure that all your paid ad campaigns are in tune with the latest industry standards. If you are looking for professional support in managing your Paid advertisements or if you want us to review your present ad campaign setup, then contact us immediately to find out more.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsPAM;