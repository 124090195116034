import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsISCS extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Understanding Digital marketing case studies is one of the best ways to gain inspiration from other businesses. An in-depth digital marketing case study can help your leads make more informed decisions. You can know about the pain points, learn from the mistakes made by other marketers, and avoid committing those mistakes while framing their digital marketing strategies and activities.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Each case study targets different segments of the business and audiences. Effective digital marketing case studies can improve your conversion rate and also helps you save time in research activities, and you can concentrate more on your business development. We will help you choose your case study related to your business, potential customers, and how it can aid you in your business development. </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Digital marketing case studies are an invaluable asset to those who started exploring the market and also to experienced players.  Through case studies we can easily understand and add value to our businesses. We will help our clients create a digital marketing case study for their business to have an edge-over their competitors.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsISCS;