import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";
import { FiCheck } from "react-icons/fi";

const DigiCademy = () => {
    return (
        <>
            <PageHelmet pageTitle="☑️Digital Marketing Academy | Digitoze Online Training | ISDB" pageDescription="The Digitoze Academy Online provided DIgital Marketing training for both face-to-face but also online training, as well as topical online-only training sessions. ISDB Indian School of Digital Business Hyderabad" />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'DigiCademy'}   />
            {/* End Breadcrump Area */}
            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                 {/* Start About Area  */}
                 <div className="rn-about-area pt--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title align-items-center text-center">
                                        {/* <h2 className="title">Build Marketing Funnel</h2> */}
                                        <p className='align-items-center text-center'>We provide excellent training and guidance for digital enthusiasts and digipreneurs. We provide the latest technology-based courses to give you a competitive edge over others.</p>
                                        <div className="about-button mt--0 text-center align-items-center">
                            <a className="rn-button-style--2 btn-solid" href="https://isdbacademy.in/"  rel="noopener noreferrer" target="_blank">More Details About Academy</a>
                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start About Area  */}
                 <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digicademy2.webp" alt="DigiCademy"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">DIGITAL MARKETING</h2>
                                        <ul className='list-style--1'>
                                            <li><FiCheck /> Search Engine Optimization (On-page SEO, Off-Page SEO) </li>
                                            <li><FiCheck /> Search Engine Marketing </li>
                                            <li><FiCheck /> Social Media Optimization </li>
                                            <li><FiCheck /> Social Media Advertising </li>
                                            <li><FiCheck /> PPC- Pay Per Click, pay to click, PPC marketing </li>
                                            <li><FiCheck /> Google Ad Management </li>
                                            <li><FiCheck /> Lead Generation </li>
                                            <li><FiCheck /> Content Marketing </li>
                                            <li><FiCheck /> Brand Awareness </li>
                                            <li><FiCheck /> Data and Analytics </li>
                                        </ul>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">WEB TECHNOLOGY</h2>
                                        <ul className='list-style--1'>
                                            <li><FiCheck /> Creative Designing </li>
                                            <li><FiCheck /> Web Integrated Services </li>
                                            <li><FiCheck /> Web Portal Development </li>
                                            <li><FiCheck /> E-commerce Web Apps </li>
                                            <li><FiCheck /> Business Website </li>
                                            <li><FiCheck /> Full-Stack Web Development Services </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digicademy3.webp" alt="DigiCademy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start About Area  */}
                 <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digicademy4.webp" alt="DigiCademy"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">SOFT SKILLS</h2>
                                        <ul className='list-style--1'>
                                            <li><FiCheck /> Personality Development </li>
                                            <li><FiCheck /> Effective Communication Skills </li>
                                            <li><FiCheck /> Presentation Skills </li>
                                            <li><FiCheck /> Leadership Skills </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h4 className="title">DIGIPRENEUR END TO END TRAINING, GUIDANCE & LAUNCH OF DIGITAL STARTUPS</h4>
                                        <ul className='list-style--1'>
                                            <li><FiCheck /> Short Term & Long Term Course </li>
                                            <li><FiCheck /> Assess & Create Business Plan </li>
                                            <li><FiCheck /> Feasibility Analysis </li>
                                            <li><FiCheck /> Business Proof Of Concept </li>
                                            <li><FiCheck /> Commercial Proof of Concept </li>
                                            <li><FiCheck /> Pre & Post Launch Strategy </li>
                                            <li><FiCheck /> Expansion & Franchising Business PAN India/ Global Level </li>
                                            <li><FiCheck /> Partnership Model </li>
                                            <li><FiCheck /> Time to Time Guidance </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digicademy1.webp" alt="DigiCademy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default DigiCademy;