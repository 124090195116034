import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabWebCMS from "../elements/tab/TabWebCMS";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const CMSDevelopment = () => {
    return (
        <>
            <PageHelmet pageTitle="CMS Development" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'CMS Development'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start About Area */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/webcms.webp" alt="About Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--20">
                                                        <p className="description">Content management systems (CMS) are important for B2B companies. CMS takes out the stress of managing a website. It allows developers to quickly build a website for your businesses that drives traffic to your website. With a CMS in place, you can spend very less time staring at your computer screen and more time on your business development.</p>

                                                        <p className="description">Some of the advantages of CMS are: you can easily integrate your website with other business applications, customize your website to meet the desired business needs, ensure consistency in the appearance of your website, update your website remotely, and at any time, eliminate the need for web developers for regular content updates, optimizes your website and content for mobile use or search engines and also reduces website maintenance costs.</p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabWebCMS tabStyle="tab-style--1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default CMSDevelopment;