import React, { Component } from "react";

class BrandTwoInner extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/1.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/2.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/3.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/4.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/5.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/6.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/7.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/8.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/9.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
					<li>
                        <img src="/assets/images/brand/10.webp"  width="auto" height="auto" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwoInner;