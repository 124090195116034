import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PortfolioListHome from "../elements/portfolio/PortfolioListHome";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const DigiServe = () => {
    return (
        <>
            <PageHelmet pageTitle="☑️Top 10 Best Digital Marketing Services - 2022  - 2023 | Hyderabad | Dubai | USA | Australia" pageDescription="☑️Top 10 Best Digital Marketing Services in Hyderabad- 2022 - 2023 Review We are a Digitoze - digital marketing agency that assists businesses of all sizes in identifying and reaching their target audiences online." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'DigiServe'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                  {/* Start Portfolio Area - Our Services*/}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <p>Our team is a creative powerhouse with the tools and resources you need to grow your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioListHome styevariation="text-center mt--40" column="col-lg-3 col-md-6 col-sm-6 col-12" item="4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default DigiServe;