import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
// import BrandOne from "../elements/Brand";
// import BrandTwo from "../elements/BrandTwo";
//import DigitalMarketingList from "../elements/services/DigitalMarketingList"
//import { Parallax } from "react-parallax";
//import CounterOne from '../elements/counters/CounterOne';

// const SlideList = [
//     {
//         textPosition: 'text-center',
//         category: '',
//         title: 'Creative One Page',
//         description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     }
// ]

const image6 =
  "/assets/images/bg/paralax/bg-image-6.webp";


const HowWeWork = () => {
    return (
        <>
            <PageHelmet pageTitle="Custom CMS Web Design & Development Company" pageDescription="We are a marketing firm that is technologically savvy. We design digital experiences that people enjoy. By delivering long-term, sustainable results for your brand, we will assist you in attracting, converting, and retaining customers." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Privacy Policy'}   />
            {/* End Breadcrump Area */}
            
            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                 {/* Start About Area */}
                 <div className="about-area ptb--60 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <p className="description">Welcome to Digitoze.com! Your privacy is of paramount importance to us. This Privacy Policy document outlines the types of personal information that is collected, recorded, and how we use it when you visit our website or use our services. By accessing or using Digitoze.com, you agree to the terms outlined in this policy.</p>

                                            <h3 className="title">Information We Collect </h3>
                                            <p className="description">We collect different types of information to provide and improve our services for you.</p>

                                            <h4 className="title">1. Personal Information </h4>
                                            <p className="description">When you interact with our website or services, we may collect personally identifiable information such as your name, email address, phone number, and other contact details. You voluntarily provide this information when filling out forms, subscribing to our newsletters, or communicating with us.</p>

<h4 className="title">2. Usage Data</h4>
<p className="description">We automatically collect certain data when you access Digitoze.com. This may include:

Your IP address, browser type, version, and language preferences.
The pages of our website that you visit, the time spent on those pages, and the links you click.
The date and time of your visit, the devices you use, and other diagnostic data.</p>

<h4 className="title">3. Cookies and Tracking Technologies</h4>
<p className="description">We use cookies, web beacons, and similar tracking technologies to track user activity on our site and store certain information. Cookies are small data files placed on your device that help us improve your user experience. You can control the use of cookies through your browser settings.</p>

<h4 className="title">Types of cookies we use:</h4>
<p className="description">Essential Cookies: These are necessary for the website to function properly.
Analytics Cookies: These help us understand how visitors interact with our site.
Advertising Cookies: Used to deliver relevant ads based on your browsing habits.</p>

<h4 className="title">How We Use Your Information</h4>
<p className="description">We use the information we collect in various ways, including:

<ul>
   <li>To provide, operate, and maintain our website and services.</li>
   <li>To improve, personalize, and expand our services.</li>
   <li>To understand and analyze how you use our website.</li>
   <li>To communicate with you, including sending updates, promotional offers, and marketing content.</li>
   <li>To process transactions and manage orders.</li>
   <li>To comply with legal obligations and enforce our policies.</li>
</ul></p>

<h4 className="title">Sharing and Disclosure of Information</h4>
<p className="description">We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following situations:
<ul>
   <li>
Service Providers: We may share your information with trusted third-party vendors who assist us in operating our website and providing our services.</li>
<li>Legal Requirements: We may disclose your information when required to do so by law, or when we believe in good faith that such action is necessary to comply with legal obligations, protect our rights, or ensure the safety of our users.</li></ul></p>

<h4 className="title">Data Security</h4>
<p className="description">We prioritize the security of your data and take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

<h4 className="title">Your Data Protection Rights</h4>
<p className="description">Depending on your location, you may have the following rights regarding your personal information:
<ul>
   <li>Access: You can request a copy of the personal data we hold about you.</li>
   <li>Correction: You can request that we correct inaccurate or incomplete data.</li>
   <li>Deletion: You can request the deletion of your personal information, subject to legal obligations.</li>
   <li>Object to Processing: You have the right to object to the processing of your data.</li>
   <li>Data Portability: You can request the transfer of your data to another service provider.</li></ul>
If you wish to exercise any of these rights, please contact us using the details provided below.

</p>

<h4 className="title">Third-Party Links</h4>
<p className="description">Our website may contain links to other websites. We are not responsible for the privacy practices or content of those sites. We recommend reviewing the privacy policies of any third-party websites you visit.</p>

<h4 className="title">Children's Privacy</h4>
<p className="description"><p className="description">Digitoze.com does not knowingly collect or solicit personal information from children under the age of 13. If we learn that we have collected personal data from a child without verification of parental consent, we will delete that information as quickly as possible.</p>

<h4 className="title">Changes to This Privacy Policy</h4>
We may update our Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this page periodically. Your continued use of our website after any modifications indicate your acknowledgment and acceptance of the updated terms.</p>

<h4 className="title">Contact Us</h4>
<p className="description">If you have any questions or concerns about this Privacy Policy, please contact us at:

<ul>
   <li>Email: info@digitoze.com</li>
   <li>Address: # First Floor, Plot No 6-2-47 Mega city No. 1714, Yeturu Towers, A.C.Guards, Hyderabad, 500004</li>
   </ul>
</p>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default HowWeWork;