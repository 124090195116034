import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabISDF from "../elements/tab/TabISDF";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const DigitizementofFactory = () => {
    return (
        <>
            <PageHelmet pageTitle="Digitizement of Factory" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Digitizement of Factory'}   />
            {/* End Breadcrump Area */}
            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                  {/* Start About Area */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/isdf.webp" alt="Digitizement of Factory"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--20">
                                                        <p className="description">In this competitive market, the ever-changing customer demand and faster delivery expectations put a lot of strain on factories. Digitization has transformed the factory’s functioning. Now designs are completed in a 3D CAD file, drawings are sent to tablets built especially for that purpose, there is extensive use of google docs, excel, and the list goes on.</p>

                                                        <p className="description">Digitoze centralizes factory data generated by sensors, machines, MES, ERP, quality systems, and excel-based information to optimize resource efficiency and productivity, enhance operational visibility, and, ultimately, build better products for less price. We help manufacturers implement digitization to consolidate their plant operations and machine data to generate actionable insights.</p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabISDF tabStyle="tab-style--1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default DigitizementofFactory;