import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Accordion01About from "../elements/AccordionAbout";
//import Testimonial from "../elements/Testimonial";
import Slider from "react-slick";
//import { slideSlick } from "../page-demo/script";
import { slickDot } from "../page-demo/script";
import TestimonialContent from "../elements/testimonial/TestimonialContent";
import BrandTwoInner from "../elements/BrandTwoInner";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import Particles from 'react-particles-js';
//import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
//import CounterOne from "../elements/counters/CounterOne";


class About extends Component{
    render(){
        const TestimonialList = TestimonialContent.slice(0 , 5);
        let title = 'Top Digital Marketing Agencies & Services in Hyderabad | Dubai | USA | Australia - Digitoze',
        description = 'Digitoze - Best Digital Marketing Agency - Hyderabad - Web Internet agency offers a range of online marketing services, such as SEO Services, ☑️PPC campaign, ☑️Best social media marketing, ☑️web design and web development company.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle={title} pageDescription={description} />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About Digitoze'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-4.webp" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {/* <h2 className="title">{title}</h2> */}
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Services We Offer</h3>
                                                    <p><FiCheck /> Digital Marketing</p>
                                                    <p><FiCheck /> Social Media Marketing</p>
                                                    <p><FiCheck /> Search Engine Optimization</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h3 className="title">&nbsp;</h3>
                                                    <p><FiCheck /> Website Development</p>
                                                    <p><FiCheck /> E-commerce Development</p>
                                                    <p><FiCheck /> Mobile App Development</p>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area 2 */}
                <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">What We Do</h2>
                                        <p>Ranking at the top places on the search engines would be a wonderful success to boost any online business. To convert the guests into shoppers and to induce targeted traffic, SEO services assist you to spice up the prospect of skyrocketing the website's visibility. With our innovative SEO strategies, we tend to serve every client with a unique approach and assist you with the revenue increments.</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <Accordion01About />
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/how-we-work">See how we work</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-5.webp" alt="About Digitoze"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area 2 */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">Like What We Offer</h4>
                                <p>The Best Digital Marketing services in Hyderabad boost business growth and facilitate in taking high ROI. We resolve the problems which are highly impossible. However, Digitoze is the most effective Digital Marketing Agency in Hyderabad that will assist you to get the specified rank on the SERP, Social Media Promoting and Optimization, an unbeatable platform that helps you reach an endless audience and brings life to your business.</p>
                                <a className="rn-btn btn-white" href="/contact">Get Started</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.webp" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">What People Say</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--25 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
                            <div className="col-lg-12">
                                <Slider {...slickDot}>
                                    {TestimonialList.map((value , i ) => (
                                        <div className="blog blog-style--1 custom-review-wrap" key={i}>
                                            <div className="thumbnail">
                                                <a href="/">
                                                    <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content custom-review">
                                                <div className="review-stars">
                                                <img src={`/assets/images/review-stars.png`} alt="digitoze reviews"/>
                                                </div>
                                                <h4 className="title">{value.title}</h4>
                                                <p className="blogtype">{value.category}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div> 
                        {/* <Testimonial /> */}
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Clients</h3>
                                </div>
                                <BrandTwoInner />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

                {/* Start Footer Buttons Area  */}
                <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

            </React.Fragment>
        )
    }
}
export default About