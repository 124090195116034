import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsCCD extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Effective content can drive the company through the sales and conversion process. If your content is not relevant and inspiring, it can make your brand uninteresting to the audience, and they will get disconnected. We will build a solid base of analytics and robust comprehension for your brand and give you the best possible results from the start, irrespective of your budget. </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>At Digitoze, we have a team of creative and development staff to execute your ideas. We are popular for providing excellent content writing services. Our team of experienced content writers is passionate about writing informative and engaging content to educate potential customers and make them fall in love with your brand.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We provide tailor-made services as per the requirements of the client. From tagline writing to blog, our content developers have done it all. Our creative content will help your website drive more traffic, increase conversion, and generate more qualified leads. We have helped many businesses overcome different kinds of challenges and helped them achieve some exceptional results in the process.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsCCD;