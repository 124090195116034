import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabWebDv from "../elements/tab/TabWebDv";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const WebDevelopment = () => {
    return (
        <>
            <PageHelmet pageTitle="Web Development" pageDescription="new careers We are a perfect digital marketing agency in Hyderabad that offers end-to-end digital marketing services. Our portfolio of services includes Website Design &amp; Development, SEO, SMM, PPC, Mobile apps, and other Digital Marketing Services. We tend to surpass inability &amp; innovation that reflects in our work whereas, providing very collaborating &amp; inventive content that facilitates grabbing the attention of your audience furthermore as helps to grow your business on social media channels these days that matters the most." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Web Development'}   />
            {/* End Breadcrump Area */}
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

            {/* Start About Area */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-5">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/webdv.webp" alt="Web Development"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--20">
                                                        <p className="description">In this increasingly competitive market, more and more consumers take their online presence very seriously. The appearance and accessibility of websites have become more important than ever. Our website developers work towards creating simple, easy-to-use websites that would help you get more business. </p>

                                                        <p className="description">At Digitoze, we provide the best Web design and development services. All our websites are developed on an easy-to-use content management system so that our clients can easily update and manage their websites at any time, without much effort. Our expert team of web developers is here to provide strategic support and technical expertise where you need it most.</p>
                                                    </div>
                                                    <div className="row mt--30">
                                                        <TabWebDv tabStyle="tab-style--1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default WebDevelopment;