import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsWebM extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>The maintenance of the website depends on how it is built as there are a lot of things that you will need to do to maintain it. Right from the theme up-gradation to plug installs, we will help you with everything. No matter how big or small updates your website requires, our experienced developers and designers will make the relevant changes to your website.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Don’t get worried about your website maintenance. Just call us whenever you want something updated, and we will do it on your behalf. Our expert team knows exactly how to optimize your website and keep your content fresh, relevant, and search engine friendly. We will timely inform you about the completion of the task, leaving you free to get on with running your business.</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>The maintenance of the website depends on how it is built as there are a lot of things that you will need to do to maintain it. Right from the theme up-gradation to plug installs, we will help you with everything. No matter how big or small updates your website requires, our experienced developers and designers will make the relevant changes to your website.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsWebM;