import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsLG extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Coming Soon</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                       <p>Coming Soon</p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                       <p>Coming Soon</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsLG;