import React from "react";
import { useState } from "react";

export default function HealthcareProvider() {
  const [hide, setHide] = useState(false);

  const toggleHide = () => {
    setHide(!hide);
  };

  return (
    <div id="button2">
      <div className="firstdiv" style={{ display: hide ? "block" : "none" }}>
        <a href="tel:+919885098850"> <img src={`/assets/images/call.png`} className="form" alt="call"/> </a>
        <a  target="_blank" href="https://web.whatsapp.com/send?phone=+919849256286"  rel="noopener noreferrer"> <img src={`/assets/images/whatsapp.png`} className="form" alt="whatsapp"/> </a>
      </div>
      
      <button onClick={toggleHide}><img src={`/assets/images/chat.png`} className="form" alt="chat"/></button>
    </div>
  );
}

