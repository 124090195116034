import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import ReactGA from 'react-ga4';
import {FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
//import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/DIGITOZE/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/digitoze/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/digitoze1/?hl=en'},
    // {Social: <FaTwitter /> , link: 'https://twitter.com/digitoze/'},
]

ReactGA.initialize('G-2S37T4GT9Q');
ReactGA.initialize('G-2S37T4GT9Q', {
     debug: true,
     titleCase: false,
     gaOptions: {
         userId: 123
     }
});

class HeaderFive extends Component{
    
        
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { logo, color , headerPosition } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.webp" alt="Digitoze" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.webp" alt="Digitoze" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.webp" alt="Digitoze" />;
        }else if(logo === 'all-dark'){
            logoUrl = <img src="/assets/images/logo/logo-all-dark.webp" alt="Digitoze" />;
        } else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.webp" alt="Digitoze" />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo.webp" alt="Digitoze" />;
        }
        
        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                <div className="header-wrapper-top" id="top-header-wrapper">
                    <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div><a href="tel:+919849256286" target="_blank"  rel="noopener noreferrer" >+91 - 984 925 6286</a></div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="icon">
                                        <FiMail />
                                    </div><a href="mailto:info@digitoze.com" target="_blank"  rel="noopener noreferrer">info@digitoze.com</a></div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    {/* <div className="icon">
                                        <FiMapPin />
                                    </div><a href="/contact" target="_blank"  rel="noopener noreferrer">Lakdikapul, Hyderabad, 500004</a> */}
                                </div>    
                                <div className="col-lg-3 col-md-4 col-12">
                                            <div className="social-share-inner">
                                                <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                    {SocialShare.map((val , i) => (
                                                        <li key={i}><a target="_blank" rel="noopener noreferrer" href={`${val.link}`}>{val.Social}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-6">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                            <li><Link to="/">Home</Link></li>
                                            <li className="has-droupdown"><Link to="/about-us" >About Us</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/how-we-work">How We Work</Link></li>
                                                    <li><Link to="/our-team">Our Team</Link></li>
                                                    <li><Link to="/happy-clients">Happy Clients</Link></li>
                                                    {/* <li><Link to="/our-portfolio">Our Portfolio</Link></li> */}
                                                    <li><Link to="/partnering-with-us">Partnering With Us</Link></li>
                                                    <li><Link to="/testimonials">Testimonials</Link></li>
                                                    <li><Link to="/careers">Careers</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-droupdown"><Link to="/digiserve" >DigiSERVE</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                                                    <li><Link to="/web-design-development">Web Design and Development</Link></li>
                                                    <li><Link to="/industrial-solutions">Industrial Solutions</Link></li>
                                                    <li><Link to="/sales-and-marketing-automation">Sales and Marketing Automation</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/digiconsult" >DigiCONSULT</Link></li>
                                            <li><Link to="/digicademy" >DigiCADEMY</Link></li>
                                            {/* <li><Link to="/educonsult" >EduCONSULT</Link></li> */}
                                            <li><Link to="/our-portfolio">Our Portfolio</Link></li>
                                            {/* <li><Link to="/blog">Blog</Link></li> */}

                                             {/*<li className="has-droupdown"><Link to="#pages" >Pages</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/blog">Blog List</Link></li>
                                                    <li><Link to="/blog-details">Blog Details</Link></li>
                                                    <li><Link to="/service">Service</Link></li>
                                                    <li><Link to="/service-details">Service Details</Link></li>
                                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                                    <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                                                    <li><Link to="/404">404</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-droupdown"><Link to="#" >Blocks</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                                    <li><Link to="/team">Team</Link></li>
                                                    <li><Link to="/service">Service</Link></li>
                                                    <li><Link to="/video-popup">Video Popup</Link></li>
                                                    <li><Link to="/progressbar">Progressbar</Link></li>
                                                    <li><Link to="/gallery">Gallery</Link></li>
                                                    <li><Link to="/counters">Counters</Link></li>
                                                    <li><Link to="/blog">Blog List</Link></li>
                                                    <li><Link to="/clint-logo">Clint Logo</Link></li>
                                                    <li><Link to="/contact-form">Contact Form</Link></li>
                                                    <li><Link to="/google-map">Google Map</Link></li>
                                                    <li><Link to="/columns">Columns</Link></li>
                                                    <li><Link to="/pricing-table">Pricing Table</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/contact" >Contact</Link></li> */}
                                        </ul>
                                    </nav>
                                    <div className="header-btn">
                                        <a className="rn-btn " href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderFive;