import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const SalesandMarketingAutomation = () => {
    return (
        <>
            <PageHelmet pageTitle="Best Sales and Marketing Services Automation Company in India | Branding  Agency" pageDescription="Best Sales and Marketing Services in Hyderabad to help your business grow. Creating a new brand position, expanding the brand, and reorienting the market" />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Sales and Marketing Automation'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                  {/* Start About Area 1 */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            <div className="col-lg-3">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/sma1.webp" alt="Sales and Marketing Automation"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--10">
                                                        <p className="description">Free your staff from repetitive administrative tasks and let them focus on other important aspects of business development.  We will streamline your operations and reduce the load on your team with a range of intuitive marketing automation solutions like Salesforce, HubSpot etc based on your budget and business.</p>

                                                        <p className="description">Our sales and marketing automation services can help you discover new prospects and personalize your customer experience at every touchpoint, thereby driving your business growth. We have helped many businesses to deploy a sales and marketing automation strategy that combines profiling and behavioural data to deliver great results.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area 1 */}

                             {/* Start About Area 2 */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                        <div className="col-lg-1">&nbsp;</div>
                                            <div className="col-lg-10">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/sma2.webp" alt="Sales and Marketing Automation"/>
                                                </div>
                                            </div>
                                        <div className="col-lg-1">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area 2 */}

                             {/* Start About Area 3 */}
            <div className="about-area ptb--60  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35">
                                            

                                            <div className="col-lg-9">
                                                <div className="about-inner inner">
                                                    <div className="section-title ptb--10">
                                                        {/* <h2 className="title">Search Engine Optimization</h2> */}
                                                        <p className="description">Micro, Small and Medium Scale Business Enterprises (MSMEs) can Automate their Sales Processes to Maximize ROI, Shortens Sales Cycle, Improve Sales Efficiency, Increase Revenue, Increase Customer Satisfaction and Reduce Operating costs and Marketing Efforts. It provides Automated Reports for tracking Sales Processes.</p>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="thumbnail">
                                                    <img className="w-100" src="/assets/images/about/sma3.webp" alt="Sales and Marketing Automation"/>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End About Area 3 */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default SalesandMarketingAutomation;