import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import { FiCheck } from "react-icons/fi";

class TabsORM extends Component{
    render(){
        let 
        tab1 = "Approach", 
        tab2 = "Talented Team",
        tab3 = "Support";
        const { tabStyle } = this.props;
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>It’s quite challenging to successfully manage a reputation online, especially in the times of crisis situation. We have expertise across all forms of online reputation management. Our services include community management, review management, release quality news to the press, public relations, social media marketing, online marketing, search engine optimization strategies, manage negative feedback and customer service tactics.</p>                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We are specialists in delivering positive and impactful online reputation. Our team will perform a reputation audit and develop different public relation strategies and help you develop new positive relationships. We will take care of your reputation challenges (if you already have any), build strong online reputation and put you back on track.  </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>Reputation is hard-earned, and don’t let harmful comments by a few staff or customers ruin your online reputation. An impressive brand image across multiple online platforms can help your business reach a wide range of audiences. Our team will provide good support in restoring your tarnished company image to its former glory and bring more business opportunities to your doors.</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsORM;