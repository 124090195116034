import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabPAM from "../elements/tab/TabPAM";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const PaidAdManagement = () => {
    return (
        <>
            <PageHelmet pageTitle="Best PPC Companies in India  | ☑️Google Adwords Management Services" pageDescription="Digitoze is the Best Lead Generation company in Hyderabad- ⏭️Digitoze is a full-service Best Pay-per-click company for your company or organization." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Paid Ad Management'}   />
            {/* End Breadcrump Area */}
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/pam.webp" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title ptb--20">
                                            {/* <h2 className="title">Search Engine Optimization</h2> */}
                                            <p className="description">Paid advertising is one of the best ways to generate leads and online sales. Digitoze is the best lead generation company in Hyderabad. Our paid ad campaigns will advertise your business to your target audience, drive relevant traffic to your website and generate new business revenue. </p>

                                            <p className="description">Our clients are based worldwide, and we pride ourselves on offering one of the best Paid Advertisement Management services in India. If we work with you to run successful paid ads campaigns, your business can continue to grow towards a prosperous future. </p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabPAM tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default PaidAdManagement;