import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
//import TestimonialAll from "../elements/TestimonialAll";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const Testimonials = () => {
    return (
        <>
            <PageHelmet pageTitle="Testimonials -  Best Digital Marketing Company Review | E-Commerce Web Development" pageDescription="Digitoze - Testimonials -☑️E-Commerce Web Development in Hyderabad.  A full-service ☑️ Digital Marketing Solution provider with Best client satisfaction." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Testimonials'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title"> Shakir and his team are very responsive and tender to each and every need you may have for development and design. They provide a good value, and exceptionally prompt. Highly recommended.</h4>
                                        <p className="blogtype">JASON STOMEL - Founder, Cadre.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title"> I highly recommend Shakir's professional services for SEO. He has truly helped us improve our sales. He pays himself off and you'll be happy.</h4>
                                        <p className="blogtype">ANNETTE MENDOZA ATTERIDGE - Owner - Buggy Guard Inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title"> Shakir @ DIGITOZE is awesome, plain and simple. I haven't known him all that long, but when it comes to hard work, delivery, and personality, he's got it all.</h4>
                                        <p className="blogtype">USMAN AHMED - Director Overseas Education Student Connect.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">They provide very good service customised for our needs. One stop shop for all digital needs. I strongly recommend Digitoze to all.</h4>
                                        <p className="blogtype">VITAL PATHURI - Business Development Manager - ArthaYantra.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Digitoze has done good work for me. I would recommend them and have recommended them to other people. Fair and honest.</h4>
                                        <p className="blogtype">JEFF PALUMBA - Chief Technology Officer - Green Hygienics Holdings Inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Undoubtedly the best in terms of service at any time what so ever. Hard working professionals working out their core for the client's betterment. What else you ask for in this period of time.</h4>
                                        <p className="blogtype">G.S BINDRA - Business Development Manager - NGM L&T Mall.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">One of the best Digital Marketing agency. You need a partner like Digitoze to win the modern day business.</h4>
                                        <p className="blogtype">NEERAJ - Director - TCEI.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Go to Digitoze! Whether You want digital marketing services or Your company needs digital solutions an affordable price by an expert.</h4>
                                        <p className="blogtype">ABDULLAH BAIG - Management Consultant - ABM Competencify.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">About Shakir @ DIGITOZE: Shakir is a nice person to work with. A detail oriented and determined person. He is very knowledable person.</h4>
                                        <p className="blogtype">AMIT NANDA - Quality Team - CMS IT Services Pvt Ltd.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Shakir is shakir, I dont have words about shakir, he is a really a god gifted friend of mine, he is a man of motivation and entrepreneur, he always have solutions.....</h4>
                                        <p className="blogtype">VIJAY P - Creative Head - SPG Media.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">If only someone had to learn internet marketing, it would only be possible under the tutelage of Ali. His experience is his teacher. Offers wonderful suggestions and guidance..</h4>
                                        <p className="blogtype">ANIL VANUKURI - Sr Content Expert - Qualcomm.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">I now know Shakir for about 10 years . On a serious note . Shakir has couple of qualities which have worked for us when we hired services and fully satisfied.....</h4>
                                        <p className="blogtype">CHAVI HEMANTH - Founder - EBSIndiainc.com.</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Highly recommend Shakir and his team who did my company’s work. They are knowledgeable and quick on action. I wish them the very best for great future.</h4>
                                        <p className="blogtype">NISHANT JOSHI - Managing Director - TopValueSolar.com.au.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Digitoze always knows what goes on and how to improve the ad performance</h4>
                                        <p className="blogtype">YASIR KHAN - Managing Director - FEDEM CONSULTING.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Digitoze one of the professional, reliable and trustworthy organization, where you get the value for your money.</h4>
                                        <p className="blogtype">AL AZMATH - Managing Director - Al Azmath Dubai.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Digitoze is one who can give all digital solutions we have a good experience with Digitoze I will love to work with them and recommend other as well.</h4>
                                        <p className="blogtype">GAURAV SRIVASTAVA - Channel Manager - Digitoze.</p>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Learning through Digitoze.com is a Boon to Us especially Shakir Ali Sir will give a keen understanding of every concept.... it is a great privilege to us to learn through this platform</h4>
                                        <p className="blogtype">ROHITHA TATA - Student.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">I just attended the 5 day workshop and learnt basics of social media marketing and a new way to use social media not just for entertainment purpose but also for business purpose. The instructor Mr. Shakir Ali was very well understandable, his course was very well prepared and structured.</h4>
                                        <p className="blogtype">HAZIQ TAREQ SALEEM - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Shakir you taught us very well.We have learnt a lot of things in such a small span of time. Your way of teaching is simple that makes us understand but very powerful to keep us motivated to learn more.</h4>
                                        <p className="blogtype">POONAM SHARMA - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Shakir Sir is one of the best persons I ever met. I know him last 15 years and he is a strong and a good mentor. Highly recommended, and Sir thank you so much for all your support.</h4>
                                        <p className="blogtype">RAHUL GUPTA - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">DIGITOZE is a very reliable service provider of India. It has long experience and one of the best Academy and training provider. I have wonderful experience.Thank you Digitoze for helping me.</h4>
                                        <p className="blogtype">BIBI ISHRAT JAHAN ISHRAT - Student.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">It Was an amazing experience to learn Digital Marketing at Digitoze.Unexpected service from Shakir sir.</h4>
                                        <p className="blogtype">MOHAMMED HATEEM - Student.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Thank you Mr. Shakir Ali Sir for imparting us the knowledge of Digital Marketing strategy in a best and simple way.</h4>
                                        <p className="blogtype">ANAKSHI DHAR - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Thank you Shakir sir Digitoze Consulting Services for which I can learn something new and start a new chapter in life.Thank you Shakir Sir</h4>
                                        <p className="blogtype">AKASH GUEIN - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Thank you Sir, for being such an inspiration and guiding on nitty gritty of designing for a novice like me.</h4>
                                        <p className="blogtype">RAJESHWARI WAGH - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Shakir sir is the best coach n mentor he makes the most complicated subject easy to understand I would recommend people to join Digitoze for digital marketing courses I can assure you that you would never regret.</h4>
                                        <p className="blogtype">MOHAMED ISTHIAQUE - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">The way Shakir sir teaches has made me confident about digital marketing.</h4>
                                        <p className="blogtype">ASLAM KHAN - Student - ISL Engineering College.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Sir,It was a very detailed, enlightening, and beneficial course focusing on the current scenario.Thanks for your dedicated efforts.</h4>
                                        <p className="blogtype">ADITYA JHA - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">They deliver what they promise. Digitoze is one of the amazing marketing agencies I have come across.</h4>
                                        <p className="blogtype">PETER HENRY - Vendor.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Great place to Learn and get Digital marketing knowledge with practical.</h4>
                                        <p className="blogtype">SAJID AHMED - Student.</p>
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Transparent, prompt.. Good service</h4>
                                        <p className="blogtype">ADNAN AHMED - Vendor.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-8 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Mr. Shakir Ali of Digitoze is the right and professional person that one can count on him and his team for good quality and good value of Online Digital Marketing services with proactive response to any business todays date in this pandemic and uncertain times.Of course, if any company who is thinking of good sales towards the growth and business success... they should think of Digitoze only ! I wish Mr. Shakir Ali all the best in his business, because he always thinks of the best only for his esteemed clients... nothing else !!</h4>
                                        <p className="blogtype">GS MURTHY - Virtual-CEO - GSM ADVANTAGE INDIA.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">I personally experienced Digitoze as an professional robust service provider training Institute which provides a very hands-on experience to its clients / trainees, especially Shakir Bhai who has profound in-depth experience in the field, gives excellent all-round training experience to his students and deals with each of them on one-to-one basis and acts as a mentor.5 stars from me guys;</h4>
                                        <p className="blogtype">SAIF SIKANDER AHMED - Business Development Manager - Staravion Travels.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Mr. Ali is a very approachable and down to earth person. He is highly respected and treasured by the young learners of digital marketing.He has an amazing way of making things super easy to comprehend.Wishing Mr. Ali all the very best.</h4>
                                        <p className="blogtype">UDDIPAN NATH - Program Director - Peerless Skill Academy.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">I had no prior knowledge of digital marketing, this course helped me to understand all the basic concepts and enhance my interest in the marketing field. there is an amazing team interaction every day. Digital Marketing course is very beneficial for everyone from any field and I thank Shakir sir for this full-packed amazing digital marketing course.</h4>
                                        <p className="blogtype">FATIM UZ ZOHRA - Student - ISL Engineering College.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">I have done my digital marketing course here Shakir sir is very friendly minded his teaching skills are very good so that everyone can understand easily so I recommend that this is the right Academy to do the digital marketing.Thank you Shakir sir for being with us.....</h4>
                                        <p className="blogtype">PENDYALA RANJEETH KUMAR - Student.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">Shakir sir, is one of the best tutors I have come across in my professional life. The course is simply filled with knowledge and sirs cooperation and his drive to teach and prosper in terms of knowledge, just makes it a perfect parcel. In my opinion, the first and best stop for Digital Marketing Consulting would be this! Thank you sir, and Digitoze will definitely bloom to its best, InshaAllah.</h4>
                                        <p className="blogtype">SALWA SAYEEDUL HASAN - Student - ISL Engineering College.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">First d like to thank Shakir sir for interesting digital course. I ve learn a lot of new things that help me to gain more knowledge of digital marketing....Sir explain each and everything clearly and make sure we understand well....Excellent place to learn about digital marketing.</h4>
                                        <p className="blogtype">FARHA FATIMA - Student - ISL Engineering College.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 testi-wrap">
                                    <div className="blog blog-style--1 custom-review-wrap">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={`/assets/images/blog/reviews-bg-new.png`} alt="Blog Images"/>
                                        </a>
                                    </div>
                                    <div className="content custom-review">
                                        <div className="review-stars">
                                            <img src="/assets/images/review-stars.png" alt="digitoze reviews"/>
                                        </div>
                                        <h4 className="title">One if the best places to learn about DIGITAL MARKETING, coz I had my course too here. Professional as well as Friendly lecturer. Giving the students best knowledge about the subject in an easy to understandable way is his specialty. If you wanna learn about DIGITAL MARKETING this is the place that I would suggest.</h4>
                                        <p className="blogtype">SRINATH KAKULAR - Student.</p>
                                    </div>
                                </div>
                            </div>
                                    
                            
                        </div>                                   


                        {/* <TestimonialAll /> */}
                    </div>
                </div>
                {/* End Testimonial Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default Testimonials;