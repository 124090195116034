import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";
import WebDesignDevelopmentList from "../elements/services/WebDesignDevelopmentList";

const WebDesignDevelopment = () => {
    return (
        <>
            <PageHelmet pageTitle="Web Design DevelopmentBest Web Design & Development Company | Hyderabad | Dubai | USA | Australia" pageDescription="Find the Best Web Design and Development Company - Do you want to start a business or  top website design and development company in Hyderabad? The first thing that comes to mind should be a website. You should easily get in touch with Digitoze Consltant." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Web Design Development'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                 {/* Start Service Area  */}
                 <div id="service" className="fix">
                    <div className="service-area creative-service-wrapper ptb--60 bg_color--1" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--1 mb--30 mb_sm--0">
                                        <p>Digitoze is an award winning digital marketing agency which provides top notch end – to – end digital marketing services to wide range of businesses from a local grocery store to a high end commercial enterprise keeping in mind the goals, objectives and budget of the clients.</p>
                                        <p>Our team of digital marketing experts deliver various services by devising relevant content strategies to meet the client’s expectation and business goals, which includes SEO, Social Media Management, generate premium leads, Email marketing, deliver excellent ROI, PPC (Pay Per Click), Ads Management and brand awareness.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row creative-service">
                                <div className="col-lg-12">
                                    <WebDesignDevelopmentList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {/* End Service Area  */} 
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default WebDesignDevelopment;