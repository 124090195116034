import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabLG from "../elements/tab/TabLG";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const LeadGeneration = () => {
    return (
        <>
            <PageHelmet pageTitle="Top Digital Lead Generation Agency | Get Digital Sales Qualified Leads Hyderabad | Dubai" pageDescription="Best Digital Lead Generation Company to Expand Your Business - Digitoze offers Complete Lead Generation at Cost Effective & help you to achive Best ROI." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Lead Generation'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/lg.webp" alt="Lead Generation Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title ptb--20">
                                            {/* <h2 className="title">Search Engine Optimization</h2> */}
                                            <p className="description">At Digitoze, we offer qualified online lead generation services to suit your business requirements. We generate targeted leads with great qualification matrix and bring excellent ROI. We provide one stop solution for lead generation; validate leads & finally Business in the inbox.</p>                                            
                                        </div>
                                        <div className="row mt--30">
                                            <TabLG tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default LeadGeneration;