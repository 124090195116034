import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const WebDesignDevelopmentList = [
    {
        icon: <FiCast />,
        title: 'Web Design',
        description: 'We design and build much more than attractive websites. Whether its a start-up or a large organization, the end-user is always in front of our mind.',
        buttonLink: '/web-design'
    },
    {
        icon: <FiLayers />,
        title: 'Web Development',
        description: 'The appearance and accessibility of websites have become more important than ever. We develop easy-to-use websites to help your business grow.',
        buttonLink: '/web-development'
    },
    {
        icon: <FiUsers />,
        title: 'CMS Development',
        description: 'With a CMS in place, you can spend less time staring at your computer screen and more time on your business development.',
        buttonLink: '/cms-development'
    },
    { 
        icon: <FiMonitor />,
        title: 'Custom Application Development',
        description: 'We develop tailor-made software solutions for your business needs, integrate them with your existing workflow, and automate complex processes with ease.',
        buttonLink: '/custom-application-development'
    },
    {
        icon: <FiUsers />,
        title: 'E-Commerce Solutions',
        description: 'We provide an end-to-end e-commerce solution and give our clients the cutting edge to drive their online sales.',
        buttonLink: '/ecommerce-solutions'
    },
    { 
        icon: <FiMonitor />,
        title: 'Website Maintenance',
        description: 'By regularly updating plugins on your website, we can help keep your website safe from any security breaches.',
        buttonLink: '/website-maintenance'
    }
]


class WebDesignDevelopmentThree extends Component{
    render(){
        const {column } = this.props;
        const WebDesignDevelopmentContent = WebDesignDevelopmentList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {WebDesignDevelopmentContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={`${val.buttonLink}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default WebDesignDevelopmentThree;
