import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import TabSEO from "../elements/tab/TabSEO";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";

const SearchEngineOptimization = () => {
    return (
        <>
            <PageHelmet pageTitle="#Top 10 Best SEO Companies |  ☑️Ranked SEO Company - 2022 -2033 Review | Hyderabad | Dubai | USA | Australia" pageDescription="Looking for SEO services in Hyderabad? First Rank ➡️SEO Services is a professional search engine optimization company in India. We utilise current ➡️SEO tactics to boost website rankings as well as organic traffic." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Search Engine Optimization'}   />
            {/* End Breadcrump Area */}
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/seo.webp" alt="Search Engine Optimization"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title ptb--20">
                                            {/* <h2 className="title">Search Engine Optimization</h2> */}
                                            <p className="description">We live and breathe SEO. We build high-quality backlinks to drive traffic to your website. We specialize in strategies that deliver the highest return on investment for your business. We are popularly known for thinking outside the box and approaching challenges with creative digital marketing strategies to solve your business needs.</p>

                                            <p className="description">If you want your website to be on the top of the search engine results pages (SERPs), then Digitoze is perfect for you. We provide high-quality SEO services to create a strong online presence and target the right audience whose presence matters to your business. </p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabSEO tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default SearchEngineOptimization;