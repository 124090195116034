import React ,{ Component }from "react";
import { FiCast , FiLayers  } from "react-icons/fi";

const IndustrialSolutionList = [
    {
        icon: <FiCast />,
        title: 'Digitizement of Factory',
        description: 'Digitization has changed how the products are designed, developed, produced, and maintained. It has transformed the operations, and processes of factories and its supply chains.',
        buttonLink: '/digitizement-of-factory'
    },
    {
        icon: <FiLayers />,
        title: 'Digital Marketing Case Studies',
        description: 'In digital marketing, case studies act as a proof to provide customers with the relevant information to determine whether they are making a good decision or not.',
        buttonLink: '/digital-marketing-case-studies'
    }
]


class IndustrialSolutionThree extends Component{
    render(){
        const {column } = this.props;
        const IndustrialSolutionContent = IndustrialSolutionList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {IndustrialSolutionContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={`${val.buttonLink}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default IndustrialSolutionThree;
