import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import BrandOne from "../elements/Brand";
//import BrandTwo from "../elements/BrandTwo";
import OurTeams from "../elements/digitozelement/OurTeams"


const OurTeam = () => {
    return (
        <>
            <PageHelmet pageTitle="☑️Affordable Website Design Digital Marketing Expert Team  | Digitoze Consulting " pageDescription="We offer web design packages for small, medium, and large businesses at Digitoze Website Design Team Expert in Telangana. All packages include the necessary features. Call today to get the best deal." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Our Team'}   />
            {/* End Breadcrump Area */}
            
            
              {/* Start COntact Area */}
              <div id="contact" className="fix">
                    <div className="rn-contact-area ptb--60 bg_color--1">
                        <OurTeams />
                    </div>
                </div>
            {/* End COntact Area */}



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default OurTeam;