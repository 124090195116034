import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const TabOne = [
    {
        image: '/assets/images/portfolio/apparel/apparel01.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel01.webp',
        category: 'Apparel',
        title: 'MAX - Galleria Mall'
    },
    {
        image: '/assets/images/portfolio/cargo/cargo01.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo01.webp',
        category: 'Cargo',
        title: 'Alpha Star'
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
        category: 'E-Commerce',
        title: 'Bliss'
    },
    {
        image: '/assets/images/portfolio/education-training/education-training01.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training01.webp',
        category: 'Education & Training',
        title: 'IIM Nagpur'
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
        category: 'Events & Exhibition',
        title: 'Business Women Expo'
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
        category: 'Food & Beverages',
        title: 'Zohran Global'
    },
    
   
]


const TabTwo = [
    {
        image: '/assets/images/portfolio/apparel/apparel01.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel01.webp',
        category: 'Apparel',
        title: 'MAX - Galleria Mall'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel02.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel02.webp',
        category: 'Apparel',
        title: 'Lifestyle'
    },
    {
        image: '/assets/images/portfolio/apparel/apparel03.webp',
        bigImage: '/assets/images/portfolio/apparel/apparel03.webp',
        category: 'Apparel',
        title: 'Hyderabad Next'
    },
]

const TabThree = [
    {
        image: '/assets/images/portfolio/cargo/cargo01.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo01.webp',
        category: 'Cargo',
        title: ''
    },
    {
        image: '/assets/images/portfolio/cargo/cargo02.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo02.webp',
        category: 'Cargo',
        title: ''
    },
    {
        image: '/assets/images/portfolio/cargo/cargo03.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo03.webp',
        category: 'Cargo',
        title: ''
    },
]

const TabFour = [
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce01.webp',
        category: 'E-Commerce',
        title: ''
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce02.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce02.webp',
        category: 'E-Commerce',
        title: ''
    },
    {
        image: '/assets/images/portfolio/e-commerce/e-commerce03.webp',
        bigImage: '/assets/images/portfolio/e-commerce/e-commerce03.webp',
        category: 'E-Commerce',
        title: ''
    },
]

const TabFive = [
    {
        image: '/assets/images/portfolio/education-training/education-training01.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training01.webp',
        category: 'Education & Training',
        title: ''
    },
    {
        image: '/assets/images/portfolio/education-training/education-training02.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training02.webp',
        category: 'Education & Training',
        title: ''
    },
    {
        image: '/assets/images/portfolio/education-training/education-training03.webp',
        bigImage: '/assets/images/portfolio/education-training/education-training03.webp',
        category: 'Education & Training',
        title: ''
    },
]

const TabSix = [
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition01.webp',
        category: 'Events & Exhibition',
        title: ''
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition02.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition02.webp',
        category: 'Events & Exhibition',
        title: ''
    },
    {
        image: '/assets/images/portfolio/events-exhibition/events-exhibition03.webp',
        bigImage: '/assets/images/portfolio/events-exhibition/events-exhibition03.webp',
        category: 'Events & Exhibition',
        title: ''
    },
]

const TabSeven = [
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages01.webp',
        category: 'Food & Beverages',
        title: ''
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages02.webp',
        bigImage: '/assets/images/portfolio/food-beverages/cfood-beverages02.webp',
        category: 'Food & Beverages',
        title: ''
    },
    {
        image: '/assets/images/portfolio/food-beverages/food-beverages03.webp',
        bigImage: '/assets/images/portfolio/food-beverages/food-beverages03.webp',
        category: 'Food & Beverages',
        title: ''
    },
]

const TabEight = [
    {
        image: '/assets/images/portfolio/furniture/furniture01.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture01.webp',
        category: 'Furniture',
        title: ''
    },
    {
        image: '/assets/images/portfolio/furniture/furniture02.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture02.webp',
        category: 'Furniture',
        title: ''
    },
    {
        image: '/assets/images/portfolio/furniture/furniture03.webp',
        bigImage: '/assets/images/portfolio/furniture/furniture03.webp',
        category: 'Furniture',
        title: ''
    },
]

const TabNine = [
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences01.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences01.webp',
        category: 'Health Sciences',
        title: ''
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences02.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences02.webp',
        category: 'Health Sciences',
        title: ''
    },
    {
        image: '/assets/images/portfolio/health-sciences/health-sciences03.webp',
        bigImage: '/assets/images/portfolio/health-sciences/health-sciences03.webp',
        category: 'Health Sciences',
        title: ''
    },
]

const TabTen = [
    {
        image: '/assets/images/portfolio/hitech/hi-tech01.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech01.webp',
        category: 'Hi-Tech',
        title: ''
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech02.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech02.webp',
        category: 'Hi-Tech',
        title: ''
    },
    {
        image: '/assets/images/portfolio/hitech/hi-tech03.webp',
        bigImage: '/assets/images/portfolio/hitech/hi-tech03.webp',
        category: 'Hi-Tech',
        title: ''
    },
]

const TabEleven = [
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing01.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing01.webp',
        category: 'Manufacturing',
        title: ''
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing02.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing02.webp',
        category: 'Manufacturing',
        title: ''
    },
    {
        image: '/assets/images/portfolio/manufacturing/manufacturing03.webp',
        bigImage: '/assets/images/portfolio/manufacturing/manufacturing03.webp',
        category: 'Manufacturing',
        title: ''
    },
]

const TabTwelve = [
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment01.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment01.webp',
        category: 'Media & Entertainment',
        title: ''
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment02.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment02.webp',
        category: 'Media & Entertainment',
        title: ''
    },
    {
        image: '/assets/images/portfolio/media-entertainment/media-entertainment03.webp',
        bigImage: '/assets/images/portfolio/media-entertainment/media-entertainment03.webp',
        category: 'Media & Entertainment',
        title: ''
    },
]

const TabThirteen = [
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous01.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous01.webp',
        category: 'Miscellaneous',
        title: ''
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous02.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous02.webp',
        category: 'Miscellaneous',
        title: ''
    },
    {
        image: '/assets/images/portfolio/miscellaneous/miscellaneous03.webp',
        bigImage: '/assets/images/portfolio/miscellaneous/miscellaneous03.webp',
        category: 'Miscellaneous',
        title: ''
    },
]

const TabFourteen = [
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality01.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality01.webp',
        category: 'Travel & Hospitality',
        title: ''
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality02.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality02.webp',
        category: 'Travel & Hospitality',
        title: ''
    },
    {
        image: '/assets/images/portfolio/travel-hospitality/travel-hospitality03.webp',
        bigImage: '/assets/images/portfolio/travel-hospitality/travel-hospitality03.webp',
        category: 'Travel & Hospitality',
        title: ''
    },
]

const TabFifteen = [
    {
        image: '/assets/images/portfolio/cargo/cargo01.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo01.webp',
        category: 'Cargo',
        title: ''
    },
    {
        image: '/assets/images/portfolio/cargo/cargo02.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo02.webp',
        category: 'Cargo',
        title: ''
    },
    {
        image: '/assets/images/portfolio/cargo/cargo03.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo03.webp',
        category: 'Cargo',
        title: ''
    },
]

const TabSixteen = [
    {
        image: '/assets/images/portfolio/cargo/cargo01.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo01.webp',
        category: 'Cargo',
        title: ''
    },
    {
        image: '/assets/images/portfolio/cargo/cargo02.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo02.webp',
        category: 'Cargo',
        title: ''
    },
    {
        image: '/assets/images/portfolio/cargo/cargo03.webp',
        bigImage: '/assets/images/portfolio/cargo/cargo03.webp',
        category: 'Cargo',
        title: ''
    },
]

class TabStyleThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const {column } = this.props;
        const { tab1, tab2, tab3, tab4, isOpen } = this.state;
        return (
            <div>
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    <Tab><span>All Project</span></Tab>
                                    <Tab><span>Apparel</span></Tab>
                                    <Tab><span>Cargo</span></Tab>
                                    <Tab><span>E-Commerce</span></Tab>
                                    <Tab><span>Education &amp; Training</span></Tab>
                                    <Tab><span>Events &amp; Exhibition</span></Tab>
                                    <Tab><span>Food &amp; Beverages</span></Tab>
                                    <Tab><span>Furniture</span></Tab>
                                    <Tab><span>Health Sciences</span></Tab>
                                    <Tab><span>Hi-Tech</span></Tab>
                                    <Tab><span>Manufacturing</span></Tab>
                                    <Tab><span>Miscellaneous</span></Tab>
                                    <Tab><span>Travel &amp; Hospitality</span></Tab>
                                </TabList>
                            </div>
                        </div>
                    </div>

                    <TabPanel className="row row--35">
                        {TabOne.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabOne[tab1].bigImage}
                                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                        prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + 1) % TabOne.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        {/* <img src={`/assets/images/portfolio/apparel/apparel${value.image}.webp`} alt="Portfolio Images"/> */}
                                                        <img src={`${value.image}`} alt="All Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
            
                    <TabPanel className="row row--35">
                        {TabTwo.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabTwo[tab2].bigImage}
                                        nextSrc={TabTwo[(tab2 + 1) % TabTwo.length]}
                                        prevSrc={TabTwo[(tab2 + TabTwo.length - 1) % TabTwo.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab2: (tab2 + TabTwo.length - 1) % TabTwo.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab2: (tab2 + 1) % TabTwo.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Apparel Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabThree.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabThree[tab3].bigImage}
                                        nextSrc={TabThree[(tab3 + 1) % TabThree.length]}
                                        prevSrc={TabThree[(tab3 + TabThree.length - 1) % TabThree.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab3: (tab3 + TabThree.length - 1) % TabThree.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab3: (tab3 + 1) % TabThree.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab3: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFour.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFour[tab4].bigImage}
                                        nextSrc={TabFour[(tab4 + 1) % TabFour.length]}
                                        prevSrc={TabFour[(tab4 + TabFour.length - 1) % TabFour.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFour.length - 1) % TabFour.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFour.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFive.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFive[tab4].bigImage}
                                        nextSrc={TabFive[(tab4 + 1) % TabFive.length]}
                                        prevSrc={TabFive[(tab4 + TabFive.length - 1) % TabFive.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFive.length - 1) % TabFive.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFive.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabSix.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabSeven.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabEight.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabNine.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabTen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabEleven.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabTwelve.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabThirteen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFourteen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFifteen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabSixteen.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab4].bigImage}
                                        nextSrc={TabSix[(tab4 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab4 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#portfolio-details">
                                                        <img src={`${value.image}`} alt="Cargo Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                </Tabs>
            </div>
        )
    }
}


export default TabStyleThree
