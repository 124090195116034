const BlogContent = [
    {
        images: '01',
        title: ' Web Design & Development Expert',
        description: 'At Digitoze, we initially start by analyzing the projects and emphasize on your present marketing efforts.'
    },
    {
        images: '02',
        title: '360 Degree eCommerce Portal Development',
        description: 'We develop eCommerce websites that are fully responsive and compatible with all the latest devices.'
    },

    {
        images: '03',
        title: 'Google Ranking & Ad Management',
        description: 'Our projects are optimized with basic SEO standards, which will help you grow your sales.'
    },
    {
        images: '04',
        title: 'Social Media Marketing & Ad Management',
        description: 'We develop eCommerce websites that are fully responsive and compatible with all the latest devices.'
    },
    {
        images: '01',
        title: ' Web Design & Development Expert',
        description: 'At Digitoze, we initially start by analyzing the projects and emphasize on your present marketing efforts.'
    },
    {
        images: '02',
        title: '360 Degree eCommerce Portal Development',
        description: 'We develop eCommerce websites that are fully responsive and compatible with all the latest devices.'
    },

    {
        images: '03',
        title: 'Google Ranking & Ad Management',
        description: 'Our projects are optimized with basic SEO standards, which will help you grow your sales.'
    },
    {
        images: '04',
        title: 'Social Media Marketing & Ad Management',
        description: 'We develop eCommerce websites that are fully responsive and compatible with all the latest devices.'
    },
    {
        images: '01',
        title: ' Web Design & Development Expert',
        description: 'At Digitoze, we initially start by analyzing the projects and emphasize on your present marketing efforts.'
    },
    {
        images: '02',
        title: '360 Degree eCommerce Portal Development',
        description: 'We develop eCommerce websites that are fully responsive and compatible with all the latest devices.'
    }

]

export default BlogContent;