import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
//import Accordion01About from "../elements/AccordionAbout";
//import Testimonial from "../elements/Testimonial";
//import Slider from "react-slick";
//import { slideSlick } from "../page-demo/script";
//import { slickDot } from "../page-demo/script";
import TestimonialContent from "../elements/testimonial/TestimonialContent";
import BrandTwoInner from "../elements/BrandTwoInner";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
//import { FiCheck } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
//import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
//import Particles from 'react-particles-js';
//import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
//import CounterOne from "../elements/counters/CounterOne";


class About extends Component{
    render(){
        //const TestimonialList = TestimonialContent.slice(0 , 5);
        let title = 'Top Digital Marketing Agencies & Services in Hyderabad | Dubai | USA | Australia - Digitoze',
        description = 'Digitoze - Best Digital Marketing Agency - Hyderabad - Web Internet agency offers a range of online marketing services, such as SEO Services, ☑️PPC campaign, ☑️Best social media marketing, ☑️web design and web development company.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle={title} pageDescription={description} />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Our Blog'}   />
                {/* End Breadcrump Area */}

                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <BlogList />
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                {/* <Pagination /> */}
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}

                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Clients</h3>
                                </div>
                                <BrandTwoInner />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

                {/* Start Footer Buttons Area  */}
                <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

            </React.Fragment>
        )
    }
}
export default About