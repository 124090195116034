import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
import BrandOne from "../elements/Brand";
import AccordionDC from "../elements/AccordionDC";
//import BrandTwo from "../elements/BrandTwo";

const DigiConsult = () => {
    return (
        <>
            <PageHelmet pageTitle="Best Digital Marketing Advertising Consultancy Services Hyderabad | Outsource Your Digital Marketing" pageDescription="Hyderabad's most effective digital marketing advertising agency. Digitoze assists you in setting up Digital Marketing Team & Setting up Process and operate in BOT Model. If you want to Setup Your Dedicated Digital Marketing Team or Business Plan to Setup Your DIgital Business - Contact #Digitoze" />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'DigiConsult'}   />
            {/* End Breadcrump Area */}
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digiconsult1.webp" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title ptb--20">
                                        <p>We provide proven and effective digital consultancy services so that your company remains competitive now and in the future too. Our dedicated digital consultancy would help your brand to stand-out in the digital ecosystem.</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <AccordionDC />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-8">
                            <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Our Team of Proficient Consultants Will Analyze Your Product / Services</h2>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <p>Our experienced UI/UX consultants will study your product/service in detail and suggest the right tone for your brand and create designs accordingly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digiconsult2.webp" alt="DigiConsult"/>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Entrepreneurship Eco System</h2>
                                        <p>We will be of service and assist in setting up complete entrepreneurship eco system for increasing more stake holders and also for young entrepreneurs & seasoned professionals to come together and act as business catalysts for the entrepreneurship eco system with relevant training, guidance and self-employment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                

                {/* Start About Area  */}
                <div className="rn-about-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Build Marketing Funnel</h2>
                                        <p>We carry out the systematic collection & analysis of data, evaluate your audience and conduct audits to provide the much-needed information for our clients to make successful marketing strategies and decisions so as to gain new leads and nurture them through your funnel.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/digiconsult3.webp" alt="DigiConsult"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
                

                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default DigiConsult;