const BlogContent = [
    {
        images: '01',
        title: ' Shakir and his team are very responsive and tender to each and every need you may have for development and design. They provide a good value, and exceptionally prompt. Highly recommended.',
        category: 'JASON STOMEL - Founder, Cadre.'
    },
    {
        images: '02',
        title: 'I highly recommend Shakir"s professional services for SEO. He has truly helped us improve our sales. He pays himself off and you will be happy .',
        category: 'Annette Mendoza Atteridge - Owner, Buggy Guard Inc.'
    },

    {
        images: '03',
        title: 'Shakir @ DIGITOZE is awesome, plain and simple. I have nott known him all that long, but when it comes to hard work, delivery, and personality, he has got it all.',
        category: 'Usman Ahmed - Director Overseas Education Student Connect.'
    },
    {
        images: '03',
        title: ' Undoubtedly the best in terms of service at any time what so ever. Hard working professionals working out their core for the clients betterment. What else you ask for in this period of time. ',
        category: 'G.S Bindra - Business Development Manager - NGM L&T Mall.'
    },
    {
        images: '03',
        title: ' Shakir is shakir, I dont have words about shakir, he is a really a god gifted friend of mine, he is a man of motivation and entrepreneur, he always have solutions.....',
        category: 'Vijay P - Creative Head - SPG Media.'
    },
    {
        images: '03',
        title: 'If only someone had to learn internet marketing, it would only be possible under the tutelage of Ali. His experience is his teacher. Offers wonderful suggestions and guidance..',
        category: 'Anil Vanukuri - Sr Content Expert - Qualcomm.'
    },
]

export default BlogContent;