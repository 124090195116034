import React from 'react'
import PageHelmet from "../component/common/Helmet";
//import Particles from 'react-particles-js';
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterContact from "../component/footer/FooterContact";
import FooterForm from "../component/footer/FooterForm";
// import BrandOne from "../elements/Brand";
// import BrandTwo from "../elements/BrandTwo";
import DigitalMarketingList from "../elements/services/DigitalMarketingList"
import { Parallax } from "react-parallax";
import CounterOne from '../elements/counters/CounterOne';

// const SlideList = [
//     {
//         textPosition: 'text-center',
//         category: '',
//         title: 'Creative One Page',
//         description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     }
// ]

const image6 =
  "/assets/images/bg/paralax/bg-image-6.webp";


const HowWeWork = () => {
    return (
        <>
            <PageHelmet pageTitle="Custom CMS Web Design & Development Company" pageDescription="We are a marketing firm that is technologically savvy. We design digital experiences that people enjoy. By delivering long-term, sustainable results for your brand, we will assist you in attracting, converting, and retaining customers." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'How We Work'}   />
            {/* End Breadcrump Area */}
            
            

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                 {/* Start About Area */}
                 <div className="about-area ptb--60 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title"> We Focus on Your Brand Growth </h2>
                                            <p className="description">We are a digitally-savvy marketing agency. We create digital experiences that people love. We will help you attract, convert and retain customers by delivering long-term, sustainable results for your brand. We focus on the performance and delivering Return on investment (ROI) for your brand.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.webp" alt="About Images"/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                 {/* Start CounterUp Area */}
                 <Parallax className="rn-counterup-area rn-paralax-counterup" bgImage={image6} strength={1000} >
                    <div className="counterup-area ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h3>Our Fun Facts</h3>
                                    </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div>
                </Parallax>
                {/* End CounterUp Area */}

                {/* Start About Area */}
                <div className="about-area pt--60 bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title custom-small"> We Build Strategies Considering Multiple Perspectives </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="about-inner inner">
                                        <div className="section-title">
                                            <p className="description">We develop data-driven digital marketing strategies that help your businesses grow faster. With our expertise, creativity, and insights, we can influence the entire brand experience on your present and potential customers and create a sustained impact on brand performance.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div id="service" className="fix">
                    <div className="service-area creative-service-wrapper ptb--40 bg_color--1" >
                        <div className="container">
                            <div className="row creative-service">
                                <div className="col-lg-12">
                                    <DigitalMarketingList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {/* End Service Area  */} 
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

            {/* Start Footer Buttons Area  */}
            <FooterContact />
                {/* End Footer Buttons Area  */}

                {/* Start Footer Buttons Area  */}
                <FooterForm />
                {/* End Footer Buttons Area  */}

        </>
        
    )
}

export default HowWeWork;