import React from "react";
import { useState } from "react";

export default function HealthcareProvider() {
  const [hide, setHide] = useState(false);

  const toggleHide = () => {
    setHide(!hide);
  };

  

  return (
    <div id="button1">
      
      <div className="Seconddiv" style={{ display: hide ? "block" : "none" }}>
        <form action="https://digitoze.com/email.php" method="POST">
            <h5><strong>Quick Enquiry</strong></h5>
            <div className="form-group">
                <input type="text" className="form-control" name="name" required="true" placeholder="Your Name *" id="email" />
            </div>
            <div className="form-group">
                <input type="email" className="form-control" name="email" required="true" placeholder="Your Email *" id="pwd" />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" name="phone" required="true" placeholder="Enter Phone Number *" id="pwd" />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" name="subject" required="true" placeholder="Write a Subject" id="pwd" />
            </div>
            <div className="form-group">
                <textarea type="text" className="form-control" id="item04" name="message" placeholder="Your Message"></textarea>
            </div>
            <button type="submit" className="btn btn-success btn-block" >Submit</button>
            </form>
      </div>
      <button onClick={toggleHide} className="formbtn"><img src={`/assets/images/form.png`} className="chat" alt="form"/></button>
    </div>
  );
}

